import React from "react";
import Style from "./Style";
import { OSS, Root } from "@/utils/Public";
import { Text, Mask } from "@/UI";

export default class Index extends React.Component {
  constructor(props) {
    document.title = '铺货完成'
    super(props)
    let has_remote_template = sessionStorage.getItem("has_remote_template")
    this.state={
      show_mask: true,
      has_remote_template,
    }
  }
  componentDidMount(){
    let status = sessionStorage.getItem("status")||{}
    status = status?JSON.parse(status):{}
    status.step = 3
    sessionStorage.setItem("status",JSON.stringify(status))

  }
  render() {
    return (<>
      <div style={{background:"#EFF0F7",padding:"1vh 4vw",minHeight:"98vh"}}>
        <img style={Style.true_icon} src={OSS + "true_icon.png"} />
        <div style={Style.top_tips_item}>商品已陆续上架售卖</div>
        <div style={Style.tips_title}>重要提醒</div>
        <div style={Style.tips_box}>
          {this.state.has_remote_template != 1 &&<>
            <div style={Style.tips_content_item}>
              <div style={Style.tips_content_left}>提醒一：</div>
              <div style={Style.tips_content_right}>
                <div style={{fontSize: "3.46vw"}}>您当前还未配置偏远地区不包邮运费模板，<Text style={{fontSize: "3.46vw",color:"#FC3928",fontWeight:"bold"}}>避免亏损，请务必去小店端设置。</Text></div>
                <div style={{fontSize: "3.2vw",color:"#888888"}}>（偏远地区：新疆，西藏等）</div>
              </div>
            </div>
            <div style={Style.tips_content_item}>
              <div style={Style.tips_content_left}>提醒二：</div>
              <div style={Style.tips_content_right}>
                <div style={{fontSize: "3.46vw"}}>如自动铺货商品产生订单，请在<Text style={{fontSize: "3.46vw",color:"#FC3928",fontWeight:"bold"}}>{"自动分销>采购单管理"}</Text>完成采购等操作</div>
              </div>
            </div>
            <div onClick={()=>{window.location.href=Root+"/how_set_template"}} style={Style.tips_content_button}>去设置运费模板</div>
          </>}
          {this.state.has_remote_template == 1 &&<>
            <div style={Style.tips_content_item}>
              <div style={Style.tips_content_left}>提醒一：</div>
              <div style={Style.tips_content_right}>
                <div style={{fontSize: "3.46vw"}}><Text style={{fontSize: "3.46vw",color:"#FC3928",fontWeight:"bold"}}>为避免亏损，</Text>请务必检查店铺是否已配置偏远地区不包邮运费模板</div>
                <div style={{fontSize: "3.2vw",color:"#888888"}}>（偏远地区：新疆，西藏等）</div>
              </div>
            </div>
            <div style={Style.tips_content_item}>
              <div style={Style.tips_content_left}>提醒二：</div>
              <div style={Style.tips_content_right}>
                <div style={{fontSize: "3.46vw"}}>如自动铺货商品产生订单，请在<Text style={{fontSize: "3.46vw",color:"#FC3928",fontWeight:"bold"}}>{"自动分销>采购单管理"}</Text>完成采购等操作</div>
              </div>
            </div>
            <div onClick={()=>{window.location.href=Root+"/how_set_template"}} style={Style.tips_content_button}>查看运费模板</div>
          </>}
        </div>

        {/* 底部提醒窗 */}
        {this.state.show_mask && <Mask >
          <div style={Style.mask_box}>
            <img onClick={()=>{this.setState({show_mask:false})}} style={Style.mask_box_close} src={OSS+"close_999999.png"} />
            <div style={Style.mask_box_title}>商品已在店铺上架售卖</div>
            {this.state.has_remote_template != 1  && <>
              <div style={{marginBottom:"7.2vw"}}>
                <div style={{...Style.mask_box_text, color:"#FC3928"}}><Text style={{fontSize: "4vw",color:"#FC3928",fontWeight:"bold"}}>为避免亏损，</Text>请务必在店铺设置偏远地区运费模板</div>
              </div>
              <div style={{marginBottom:"3.6vw",fontSize:"4vw",fontWeight:"bold"}}>当前您未设置，建议设置：</div>
              <div style={Style.mask_box_item}>
                <div style={{...Style.mask_box_text, color: "#001736", marginRight:"11vw"}}>1 偏远地区独立运费</div>
                <div style={{...Style.mask_box_text, color: "#001736"}}>2 偏远地区不配送</div>
              </div>
            </>}
            {this.state.has_remote_template == 1  && <>
              <div style={{...Style.mask_box_text, fontSize:"4.3vw", color:"#FC3928", textAlign:"center",fontWeight:"bold"}}>为避免亏损</div>
              <div style={{fontSize:"3.8vw", color:"#FC3928",marginTop: "2vw",textAlign:"center"}}>请务必检查店铺是否已配置偏远地区不包邮运费模板</div>
            </>}
            <div onClick={()=>{this.setState({show_mask:false})}} style={Style.mask_box_button}>知道了</div>
          </div>
        </Mask>}
      </div>
    </>)
  }
}