export default {
  top_box:{
    width: "93.46vw",
    height: "33.06vw",
    background: "#F8F8F8",
    borderRadius: "2vw",
    margin: "1.6vw 3.2vw 5.6vw 3.2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  top_box_img:{
    width:"11.33vw",
    height: "10.5vw",
    marginLeft: "5.6vw",
    marginTop: "4.2vw",
  },
  top_box_title:{
    fontWeight: "700",
    fontSize: "4vw",
    lineHeight: "5.86vw",
    color: "#000000",
    marginTop: "3.1vw",
  },
  top_box_tips:{
    fontSize: "3.2vw",
    lineHeight: "5.86vw",
    color: "#2E3136",
    marginTop: "1vw",
  },
  order_proce_step_box:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "23.7vw",
  },
  order_proce_step:{
    width: "83.46vw",
    height: "134.4vw",
  },
  order_proce_button_box:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: "0vw",
    height: "16.4vw",
    width: "100vw",
    borderTop: "1px solid #E5E5E5",
    background: "#FFFFFF",
  },
  order_proce_button:{
    width: "92.5vw",
    height: "11.5vw",
    background: "#FC3928",
    borderRadius: "11.5vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "4vw",
    color: "#ffffff",
  }
}