class Toast{
  ToastBoxStyle = ' display: flex; justify-content: center; align-items: center; background-color: rgba(0,0,0,0); position: fixed; width: 100%; height: 100%; left: 0px; top: 0px; z-index: 999999;'
  ToastContentStyle = ' padding: 10px 16px; font-size: 14px; color: #FFFFFF; background-color: rgba(0,0,0,0.7); border-radius: 5px; user-select: none; opacity: 0; transition: all 1s;'
  // text 内容文案
  // time 展示时长 false值则不关闭
  show( text , time = 3000 ,style){
    time = parseInt(time)
    let ToastBox = document.createElement('div')
    ToastBox.setAttribute('id','ToastBox')
    ToastBox.setAttribute('style', this.ToastBoxStyle)
    let ToastContent = document.createElement('div')
    ToastContent.setAttribute('style', this.ToastContentStyle)
    if(style)ToastContent.setAttribute('style', style);
    ToastContent.innerText = text
    ToastBox.appendChild(ToastContent)
    document.body.appendChild(ToastBox)
    setTimeout(()=>{ToastContent.style.opacity = 1},0)
    if( time ){
      setTimeout(() => {
        ToastContent.style.opacity = 0
        setTimeout(()=>{ToastBox.remove()},1000)
      }, time);
    }
  }
  close(){
    let ToastBox = document.getElementById('ToastBox')
    ToastBox.remove();
  }
}
window.Toast = new Toast()
export default Toast;