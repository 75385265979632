import React from "react";
import Style from "./style.js";
import { OSS, Root, Authorization } from "@/utils/Public";
import { Text, InfiniteScroll, Loading } from "@/UI";
import Dialog from "@/Component/dialog";
import SetPushGoods from "@/Component/set_push_goods";
import PushGoods from "@/API/push_goods";
import PublicQuery from "@/API/public";
import image_replace_300x300 from "@/utils/image_replace_300x300";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    document.title = '人工代运营小店'
    
    let show_page = false
    let host_setting = sessionStorage.getItem("host_setting")
    let status = sessionStorage.getItem("status")
    
    status = status?JSON.parse(status):{}
    if( status.step > 2){
      window.location.href = Root+"/small_shop_trusteeship"
    }else{
      show_page = true
    }

    host_setting = host_setting?JSON.parse(host_setting):{
      daily_goods_num: 10,
      daily_sale_type: 0,
      deal_cycle: 3,
      auto_replace_unsold: 1,
    }
    
    let build_state = "build_no"
    if (host_setting.step > 0) {
      build_state = "build_done"
    }
    let steps_manage_animation_text = [
      { title: "每日人工铺货数量", text:  <><Text style={{fontWeight:"bold",fontSize:"3.73vw"}}>50-300</Text>个</>},
      { title: "每日人工上架",text:  <>同行日销<Text style={{fontWeight:"bold",fontSize:"3.73vw"}}>100</Text>单{host_setting.daily_sale_type==1?"以下":"以上"}商品</> },
      { title: "商品最快成交周期", text: <><Text style={{fontWeight:"bold",fontSize:"3.73vw"}}>{host_setting.deal_cycle||3}</Text>日</> },
      { title: "人工更换滞销品", text: host_setting.auto_replace_unsold==0?"未开启":"已开启" },
    ]

    this.state = {
      show_page,
      build_state,
      steps_manage_animation_text,

      build_done: {
        steps_title: "人工代运营的小店："+status.shop_name,
        button_color: { background: "#477AFF" },
      },
      build_no: {
        steps_title: "平台补贴，0元绑定拼多多店铺"
      },
      steps_manage_animation_time: 0,
      steps_manage_animation_progress: 0,
      hasMore: false,
      show_dialog: false,
      show_set_push_goods: false,
      // 铺货参数
      goods_pool_list: [], // 所有商品
      host_setting,
      status,
      jump_push_goods_done_page_loading: false,
      show_invalid_authorization_dialog: false,
    }
  }
  getPushGoodsPage = 1
  async componentDidMount() {
    PublicQuery("visitRecord",{
      page_name: "autoPush_mainPage",
      control: "autoPush_mainPage_in"
    })
    let goods_pool_list = sessionStorage.getItem("goods_pool_list")
    if(!goods_pool_list){
      this.getPushGoodsPage = 1
      goods_pool_list = await this.getPushGoods() 
      sessionStorage.setItem("goods_pool_list",JSON.stringify(goods_pool_list))
    }else{
      this.getPushGoodsPage = parseInt( sessionStorage.getItem("getPushGoodsPage"))||1
      goods_pool_list = JSON.parse(goods_pool_list)
      this.setState({ hasMore:true })
    }
    this.setState({ goods_pool_list })
    this.steps_manage_animation()
  }
  async getPushGoods() {
    let params = {
      page: this.getPushGoodsPage,
    }
    sessionStorage.setItem("getPushGoodsPage", this.getPushGoodsPage)
    let res = await PushGoods("getPushGoods",params)
    if(!res.data || res.data.length==0){
      this.setState({ hasMore: false })
    }else{
      this.setState({ hasMore: true })
    }
    return res.data
  }
  // 管理步骤条样式
  render_steps(params) {
    let style_map = {
      build_done: {
        1: { icon: { background: "#27AE60" }, title: { color: "#27AE60" } },
        2: { icon: { background: "#477AFF" }, title: { color: "#477AFF" } },
        3: { icon: { background: "#477AFF" }, title: { color: "#477AFF" } },
      },
      build_no: {
        1: { icon: { background: "#477AFF" }, title: { color: "#477AFF" } },
        2: { icon: { background: "#BDBDBD" }, title: { color: "#515151" } },
        3: { icon: { background: "#BDBDBD" }, title: { color: "#515151" } },
      },
    }
    let icon_style = { ...Style.steps_icon, ...style_map[this.state.build_state][params.number]['icon'] }
    let title_style = { ...Style.steps_text, ...style_map[this.state.build_state][params.number]['title'] }
    return (
      <div>
        <div style={Style.steps_title}>
          <div style={icon_style}>{params.number}</div>
          <div style={Style.title_style_box}>
            <div style={title_style}>{params.title}</div>
          </div>
        </div>
      </div>
    )
  }
  componentWillUnmount(){
    clearInterval(this.tiemr)
  }
  // 动画逻辑
  steps_manage_animation() {
    this.tiemr = setInterval(() => {
      let steps_manage_animation_progress = this.state.steps_manage_animation_progress + 1
      let steps_manage_animation_time = parseInt(steps_manage_animation_progress / 25)
      if (steps_manage_animation_progress == 100) {
        clearInterval(this.tiemr)
      }
      this.setState({ steps_manage_animation_time, steps_manage_animation_progress })
    }, 50)
  }
  // 托管设置动画渲染
  render_steps_manage_list() {
    let elm = new Array()
    for (let i = 0; i < 4; i++) {
      elm.push(
        <div style={Style.steps_manage_item} key={"steps_manage_item" + i}>
          <div style={Style.steps_manage_item_title}>{this.state.steps_manage_animation_text[i]['title']}</div>
          {this.state.steps_manage_animation_time > i ?
            <div>
              <div style={Style.steps_manage_item_text}>{this.state.steps_manage_animation_text[i]['text']}</div>
              <img style={Style.steps_manage_item_true} src={OSS + "steps_manage_item_true.png"} />
            </div>
            :
            <img style={Style.Steps_manage_item_loading} src={OSS + "loading_blue.png"} className="RotateAnimation" />
          }
        </div>
      )
    }
    return elm
  }
  loadMore() {
    return new Promise(async (resolve) => {
      this.getPushGoodsPage = parseInt( this.getPushGoodsPage) + 1
      let goods_pool_list = await this.getPushGoods()
      let list = this.state.goods_pool_list
      list = list.concat(goods_pool_list)
      this.setState({ goods_pool_list: list })
      sessionStorage.setItem("goods_pool_list",JSON.stringify(list))
      resolve(true)
    })
  }

  render_goods_list() {
    let list = new Array()
    let goods_pool_list = this.state.goods_pool_list||[]
    goods_pool_list.map((item, index) => {
      let image = image_replace_300x300(item.images)
      list.push(<div style={Style.goods_list_item} custom_style="custom_style_radio" key={"render_goods_list_" + index}>
        <div style={Style.goods_list_item_box}>
          <img style={Style.goods_list_item_img} src={image} />
          <div>
            <div style={Style.goods_list_item_name}>{item.title}</div>
            <div style={Style.goods_list_item_leaderboard_box}>
              <div style={Style.goods_list_item_leaderboard}>
                {item.rank!="" && <img style={Style.goods_list_leaderboard_logo} src={OSS + item.rank +".png"} alt="" />}
              </div>
            </div>
            <div style={Style.goods_list_item_price}>进价  ¥{item.cost}</div>
            <div style={Style.goods_list_item_info_box}>
              <div style={Style.goods_list_item_info_sales_volume}>
                <div style={Style.goods_list_item_info_top}>同行日销</div>
                <div style={{...Style.goods_list_item_info_bottom,fontWeight:"bold"}}>{item.daily_sales}单</div>
              </div>
              <div style={Style.goods_list_item_info_split_line}></div>
              <div style={Style.goods_list_item_info_profit}>
                <div style={Style.goods_list_item_info_top}>同行日赚</div>
                <div style={{...Style.goods_list_item_info_bottom,fontWeight:"bold"}}>¥{item.daily_profit}</div>
              </div>
              <div style={Style.goods_list_item_info_cycle}>
                <div style={{ ...Style.goods_list_item_info_top, color: '#ffffff', textAlign: "end",paddingRight:"0.3vw"}}>预计出单周期</div>
                <div style={{ ...Style.goods_list_item_info_bottom, color: '#ffffff', marginTop: '1.5vw', paddingLeft: '3vw' }}>
                  <Text style={Style.goods_list_item_info_date}>{item.order_cycle}</Text>天
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
    })
    return list
  }

  setting() {
    if (this.state.build_state == "build_done") {
      PublicQuery("visitRecord",{
        page_name: "autoPush_mainPage",
        control: "autoPush_hostSetting_click"
      })
      this.setState({ show_set_push_goods: true })
    } else {
      this.setState({ show_dialog: true })
    }
  }
  push_goods() {
    if(this.state.build_state=="build_no"){
      this.setState({ show_dialog: true })
    }else{
      let visitRecordQuery = PublicQuery("visitRecord",{
        page_name: "autoPush_mainPage",
        control: "autoPush_startPushGoods_click"
      })
      Promise.all([
        PushGoods("pushGoods"),
        visitRecordQuery,
      ]).then(res=>{
        if(res[0].code == 0){
          this.setState({jump_push_goods_done_page_loading:true})
          setTimeout(()=>{
            this.setState({jump_push_goods_done_page_loading:false})
            sessionStorage.setItem("has_remote_template",res[0].data.has_remote_template)
            window.location.href = Root+"/push_goods_done"
          },2000)
        }else if(res[0].code == 34005){
          this.show_invalid_authorization_dialog_title = "您的账号授权失效"
          this.show_invalid_authorization_dialog_link = Authorization
          this.setState({show_invalid_authorization_dialog:true})
        }else if(res[0].code == 34006){
          this.show_invalid_authorization_dialog_title = "您的小店授权失效"
          this.show_invalid_authorization_dialog_link = Root+"/authorize_shop"
          this.setState({show_invalid_authorization_dialog:true})
        }else{
          window.Toast.show(res[0].msg)
        }
      })
    }
  }

  close_dialog() {
    this.setState({ show_dialog: false })
  }
  build_shop() {
    PublicQuery("visitRecord",{
      page_name: "autoPush_mainPage",
      control: "autoPush_goBindShop_click"
    }).then(res=>{
      window.location.href = Root+"/build_shop"
    })
  }
  set_auto_push_save(value) {
    this.setState({
      steps_manage_animation_text:[
        { title: "每日自动铺货数量", text:  <><Text style={{fontWeight:"bold"}}>50-300</Text>个</>},
        { title: "每日自动上架",text:  <>同行日销<Text style={{fontWeight:"bold"}}>100</Text>单{value.daily_sale_type==1?"以下":"以上"}商品</> },
        { title: "商品最快成交周期", text: <><Text style={{fontWeight:"bold"}}>{value.deal_cycle||3}</Text>日</> },
        { title: "自动更换滞销品", text: value.auto_replace_unsold==0?"未开启":"已开启" },
      ]
    })
    this.set_auto_push_close()
  }
  set_auto_push_close() {
    this.setState({ show_set_push_goods: false })
  }

  // 授权失效弹窗
  close_invalid_authorization_dialog(){
    this.setState({show_invalid_authorization_dialog:false})
  }
  // 立即授权
  now_authorization(){
    this.close_invalid_authorization_dialog()
    window.location.href = this.show_invalid_authorization_dialog_link
  }
  render() {
    return (
      <>
        {this.state.show_page && <>
          {this.state.jump_push_goods_done_page_loading && 
            <Loading text={"正在为您自动铺货，请勿关闭"}></Loading>
          }
          {this.state.show_dialog &&
            <Dialog
              onConfirmation={this.close_dialog.bind(this)}
              Confirmation="我知道了"
              title=""
            >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"10.2vw",fontSize:"4vw" }}>
              <div>请先完成绑店后再操作此项</div>
            </div>
            </Dialog>
          }
          {this.state.show_invalid_authorization_dialog &&
            <Dialog
              onConfirmation={this.now_authorization.bind(this)}
              Confirmation="立即授权"
              onCancel={this.close_invalid_authorization_dialog.bind(this)}
              Cancel="稍后授权"
              hasCancel={true}
              title={this.show_invalid_authorization_dialog_title}
            >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"20vw",fontSize:"3.7vw" }}>
              <div>已导致无法参与托管服务</div>
              <div>请重新授权后再参与</div>
            </div>
            </Dialog>
          }
          {this.state.show_set_push_goods &&
            <SetPushGoods hidden_header={true} onSave={this.set_auto_push_save.bind(this)} onClose={this.set_auto_push_close.bind(this)} />
          }
          <div style={{paddingTop:"1.5vw",}}>
            <img style={Style.index_top_background} src={OSS + "index_top_background.png"} />
          </div>
          <div style={Style.index_split_line}></div>
          <div style={Style.steps_box}>
            {this.render_steps({ number: 1, title: this.state[this.state.build_state]["steps_title"] })}
            {this.state.build_state == "build_done" && <div style={Style.steps_item_done} ></div>}
            {this.state.build_state == "build_no" && <div style={Style.steps_item}>
              <div onClick={this.build_shop.bind(this)} style={Style.steps_build_button}>0元绑店</div>
            </div>}
            {this.render_steps({ number: 2, title: "商品代运营设置" })}
            <div style={{...Style.steps_item, borderLeft:this.state.build_state=="build_done"?"1px solid #477AFF":"1px solid #C4C4C4"}}>
              <div style={Style.steps_manage_box}>
                <div style={Style.steps_manage_title}>
                  <div style={{ position: "relative" }}>
                    {this.state.steps_manage_animation_time < 4 ?
                      <img style={Style.steps_loading} className="RotateAnimation" src={OSS + 'steps_loading.png'} />
                      :
                      <img style={Style.steps_loading} src={OSS + "steps_loading_done.png"} />
                    }
                    <div style={Style.steps_loading_number}>{this.state.steps_manage_animation_progress}</div>
                  </div>
                  {this.state.steps_manage_animation_time < 4 ?
                    <Text style={Style.steps_loading_text}>
                      {this.state.steps_manage_animation_text[this.state.steps_manage_animation_time]['title']}配置中...
                    </Text>
                    :
                    <Text style={Style.steps_loading_text}>配置完成</Text>
                  }
                </div>
                <div style={Style.steps_manage_list}>
                  {this.render_steps_manage_list()}
                </div>
                <div onClick={this.setting.bind(this)} style={{ ...Style.steps_manage_button, ...this.state[this.state.build_state]["button_color"] }}>
                  <img style={Style.steps_manage_button_icon} src={OSS + "steps_manage_button_icon.png"} />
                  筛选更多设置
                </div>
              </div>
            </div>
            {this.render_steps({ number: 3, title: `优选的铺货商品` })}
            <div style={Style.goods_list}>
              {this.render_goods_list()}
              {this.state.goods_pool_list.length != 0 &&
                <InfiniteScroll loadMore={this.loadMore.bind(this)} hasMore={this.state.hasMore} />
              }
            </div>
          </div>
          {/* 底部 */}
          <div style={Style.footer_box}>
            {this.state.build_state=="build_no" && <div onClick={this.push_goods.bind(this)} style={Style.footer_button}>先绑店，再代运营</div>}
            {this.state.build_state=="build_done" && <div onClick={this.push_goods.bind(this)} style={{...Style.footer_button,background:"#477AFF"}}>立即提交人工代运营</div>}
          </div>
        </>}
      </>
    )
  }
}