import service from "@/utils/Axios";

export default function Query(type,params){
  let url = ""
  
  switch (type) {
    // 获取铺货日志
    case "getPushLog":
      url = "/h5/hosting/getPushLog"
      break;
    // 获取替换滞销品日志
    case "getReplaceLog":
      url = "/h5/hosting/getReplaceLog"
      break;
    // 确认手动替换滞销品
    case "confirmReplace":
      url = "/h5/hosting/confirmReplace"
      break;
    default:
      break;
  }

  return service({
    url,
    method: 'post',
    params
  })
}