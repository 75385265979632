import React from "react";
import Style from "./style";
import { OSS } from "@/utils/Public";
import SaveQrcodePopup from "@/Component/save_qrcode_popup";

export default class Index extends React.Component{
  constructor(props){
    document.title = "订单处理";
    super(props);
    this.state = {
      ShowSaveQrcode: false
    }
  }
  render(){
    return (<>
      <div style={Style.top_box}>
        <img src={OSS+"order_proce_top_image.png"} style={Style.top_box_img}/>
        <div style={Style.top_box_title}>您的小店已产生订单</div>
        <div style={Style.top_box_tips}>{"请立即前往【自动分销】>【采购单管理】>【支付采购款】"}</div>
      </div>
      <div style={Style.order_proce_step_box}>
        <img src={OSS+"order_proce_step.png"} style={Style.order_proce_step}/>
      </div>
      <div style={Style.order_proce_button_box}>
        <div onClick={()=>{this.setState({ShowSaveQrcode:true})}} style={Style.order_proce_button}>立即去自动分销</div>
      </div>
      {this.state.ShowSaveQrcode && 
        <SaveQrcodePopup close={()=>{this.setState({ShowSaveQrcode:false})}}></SaveQrcodePopup>
      }
    </>)
  }
}