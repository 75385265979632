import service from "@/utils/Axios";

export default function Query(type,params){
  let url = ""
  
  switch (type) {
    // 获取铺货商品池
    case "getPushGoods":
      url = "/h5/hosting/getPushGoods"
      break;
    // 根据商品id铺货
    case "pushGoods":
      url = "/h5/hosting/pushGoods"
      break;
    default:
      break;
  }

  return service({
    url,
    method: 'post',
    params
  })
}