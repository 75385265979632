import React from "react";
import Style from "./style";
import {Mask} from "@/UI";
import {OSS} from "@/utils/Public";

export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return (
      <Mask>
        <div style={Style.box}>
          <img onClick={()=>{this.props.close()}} src={OSS+"close_999999.png"} style={Style.close} />
          <img src={OSS+"scan_alibaba_image.png"} style={Style.scan_alibaba_image}/>
          <div style={Style.scan_alibaba_text}>长按保存手机后识别进入[自动分销]</div>
          <div style={Style.scan_alibaba_qrcode_box}>
            <img src={OSS+"auto_push_goods_qrcode.png"} style={Style.scan_alibaba_qrcode}/>
          </div>
        </div>
      </Mask>
    )
  }
}

