import React from "react";
import Style from "./style";
import { OSS } from "@/utils/Public"
import { Text } from "@/UI";

export default class Index extends React.Component{
  constructor(props){
    super(props);
    document.title = '如何设置运费模板'
  }
  jump_web(){
    window.location.href = ("https://mms.pinduoduo.com/")
  }
  render(){
    return(<>
      <div style={Style.box}>
        <img  style={Style.image} src={OSS+"how_set_template_background_01.png"}/>
        <div style={{color:"#666", fontWeight:"bold",paddingLeft: "4vw"}}>
          【拼多多商家版PC端】 链接：
          <Text style={Style.jump_web} onClick={this.jump_web.bind(this)}>{"https://mms.pinduoduo.com/"}</Text>
        </div>
        <img  style={Style.image} src={OSS+"how_set_template_background_03.png"}/>
        <img  style={Style.image} src={OSS+"how_set_template_background_04.png"}/>
        <img  style={Style.image} src={OSS+"how_set_template_background_05.png"}/>
        <img  style={Style.image} src={OSS+"how_set_template_background_06.png"}/>
        <img  style={Style.image} src={OSS+"how_set_template_background_07.png"}/>
        <img  style={Style.image} src={OSS+"how_set_template_background_08.png"}/>
      </div>
    </>)
  }
}