import { createSlice } from '@reduxjs/toolkit'

export const Public = createSlice({
  name: 'Public',
  initialState: {
    userInfo:{},
    setting: {},
  },
  reducers: {
    save: (state , data) => {
      state = {...state, ...data}
    },
  },
})

// Action creators are generated for each case reducer function
export const { save } = Public.actions

export default Public.reducer