export default {
  replace_unsalable_goods_split_line:{
    background: "#EFF0F7",
    height: "2.6vw",
    minHeight: "2.6vw"
  },
  unsalable_goods_title:{
    display: "flex",
    fontSize: "3.73vw",
    position: "sticky",
    padding: "4vw",
    alignItems: "top",
    borderBottom: "1px solid #EFEFEF",
    boxSizing: "border-box",
    background: "#70ADFF",
    color: "#ffffff",
  },
  unsalable_goods_box:{
    width: "100vw",
    height: "100vh",
    overflow: "auto",
    boxSizing: "border-box",
    paddingBottom: "18vw",
    position:"relative",
    display: "flex",
    flexDirection: "column",
  },
  unsalable_goods_title_tips_icon:{
    width: "4vw",
    height: "4vw",
    marginRight: "2vw",
    position: "relative",
    top: "1vw",
  },
  unsalable_goods_img:{
    width: "15.4vw",
    height: "15.4vw",
    marginRight: "2.4vw",
    borderRadius: "1vw",
    border: "1px solid #EFEFEF",
  },
  unsalable_goods_item:{
    display: "flex",
    padding: "2.8vw 4vw 4.26vw 4vw",
    boxSizing: "border-box",
  },
  unsalable_goods_list_line:{
    height: "1px",
    marginLeft: "4vw",
    position: "relative",
    background: "#EFEFEF",
  },
  unsalable_goods_list_time:{
    position: "absolute",
    height: "2px",
    lineHeight: "1px",
    background: "#ffffff",
    padding: "0vw 2.66vw",
    left: "37vw",
    color: "#9C9C9C",
  },
  footer_box:{
    width: '100%',
    height: "18vw",
    boxShadow: "0px -4px 10px -4px rgba(179, 179, 179, 0.25)",
    position: 'fixed',
    bottom: '0',
    background: '#ffffff',
    padding: "0vw 3.3vw",
    boxSizing: 'border-box',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer_button:{
    width: "44.8vw",
    height: "11.46vw",
    borderRadius: "12vw",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4vw',
  },
  footer_button_1:{
    color: "#000000",
    background: "#FFFFFF",
    border:"1px solid #000000",
  },
  footer_button_2:{
    color: "#ffffff",
    background: "#FC3928",
    border:"1px solid #FC3928",
  },
  Dialog2_text:{
    fontSize: "4.8vw",
    lineHeight: "7.2vw",
    textAlign: "center",
    color: "#333333",
  },
  replace_goods_OK_box:{
    paddingTop: "30vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  replace_goods_OK_img:{
    width: "13.3vw",
    height: "13.3vw",
    marginBottom: "8.66vw",
  },
  replace_goods_OK_text:{
    fontSize: "4.26vw",
    lineHeight: "10vw",
    textAlign: "center",
    color: "#000000",
  },
  replace_goods_OK_bottom:{
    marginTop: "8.66vw",
    width: "86vw",
    height: "12.8vw",
    borderRadius: "6.4vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "4.26vw",
    color: "#FFFFFF",
    background: "#FC3928",
  },
  unsalable_goods_item_text:{
    marginTop:"2.6vw",
    color:"#2E3136",
    fontSize:"3.73vw",
    maxWidth:"73.33vw",
    textOverflow:"ellipsis",
    overflow:"hidden",
    whiteSpace:"nowrap",
  }
}