export default {
  build_shop_top_box:{
    padding: "7vw 0vw",
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  build_shop_top_text:{
    marginTop: "7vw",
    fontSize: '4vw',
    color: '#000000',
    fontWeight: 'bold',
  },
  build_shop_top_background:{
    width: "62vw",
    height:"16.8vw",
  },
  build_shop_split_line:{
    background: "#EFF0F7",
    height: "2.6vw",
  },
  build_shop_body:{
    padding: "6.66vw",
    display: "flex",
    flexDirection: 'column',
  },
  build_shop_body_title:{
    fontSize: '4.8vw',
    color: '#000000',
    position: "relative",
    width: "100%",
    paddingLeft: "4vw",
    fontWeight: 'bold',
  },
  build_shop_body_title_icon:{
    position: 'absolute',
    width: "1.3vw",
    height: "4vw",
    background: '#477AFF',
    borderRadius: '1.3vw',
    top: '1.5vw',
    left: "0vw",
  },
  build_shop_body_title_name:{
    color:"#477AFF",
    fontSize: '4.8vw',
  },
  build_shop_Tips:{
    margin: "6.66vw 4.4vw 2.22vw 4.4vw",
  },
  build_shop_Tips_item:{
    position: "relative",
    paddingLeft: "4vw",
    marginBottom: "4.4vw",
    color: "#595959",
    fontSize: "3.73vw",
  },
  build_shop_Tips_icon:{
    position: 'absolute',
    width: "1vw",
    height: "1vw",
    left: '1vw',
    top: '1.6vw',
    background: "#595959",
    borderRadius: "1vw",
  },
  build_shop_qrcode_box:{
    width: '56.66vw',
    height: '64vw',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    margin: "0vw 14vw",
    position: 'relative',
  },
  save_qrcode_img:{
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    opacity: '0',
    zIndex: '2',
  },
  build_shop_qrcode_image_box:{
    width: "45.86vw",
    height: "45.86vw",
    position: "relative",
    margin: "5.4vw",
    marginBottom:"0vw",
  },
  build_shop_qrcode_image:{
    width: "100%",
    height: "100%",
  },
  build_shop_qrcode_icon:{
    position: 'absolute',
    width: "12.66vw",
    height: "12.66vw",
    top: "calc(50% - 6.33vw)",
    left: "calc(50% - 6.33vw)",
  },
  build_shop_qrcode_text:{
    marginTop: "3vw",
    textAlign: 'center',
    fontSize: '4.4vw',
    color: '#898989',
  },
  build_shop_button:{
    width: "56.66vw",
    height: "12.66vw",
    background: "#FFFFFF",
    border: '1px solid #477AFF',
    borderRadius: "12.66vw",
    color: '#477AFF',
    fontSize: '4.8vw',
    textAlign: "center",
    lineHeight: "12.66vw",
    margin: "12vw 14vw",
    fontWeight: 'bold',
  }
}