import React from "react";
import { Mask } from 'antd-mobile'

export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Mask 
        visible={true}
        style={this.props.style}
      >
        {this.props.children}
      </Mask>
    )
  }
}