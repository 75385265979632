import axios from "axios";
import { WebOS , TestBaseURL, BaseURL} from "./Public";
//1. 创建新的axios实例，
let baseURL = "" // 开发环境走代理,默认给空
if(process.env.NODE_ENV == 'production'){ 
  if( process.env.REACT_APP_ENV_IS_TEST=="prod" ){ // 线上环境
    baseURL = BaseURL
  }else if( process.env.REACT_APP_ENV_IS_TEST=="test" ){ // 测试环境
    baseURL = TestBaseURL
  }
}
console.log( process.env.REACT_APP_ENV_IS_TEST,"---REACT_APP_ENV_IS_TEST")
console.log( process.env.NODE_ENV,"---NODE_ENV")

const service = axios.create({
  // 公共接口
  baseURL: baseURL,
  // 超时时间 单位是ms
  timeout: 10 * 1000
});

let loading_list = new Object()

// 2.请求拦截器
service.interceptors.request.use(
  config => {
    if( WebOS == 'wx' || process.env.REACT_APP_ENV_IS_TEST=="dev" ){ // 微信环境下 设置请求头token
      config.headers['x-token'] = sessionStorage.getItem('x-token')
    }
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    if (config.method === "post") {
      // 处理参数 如果是post请求 修改params参数为data 因为Vue-cli框架中axios参数根据method分为两种
      config["data"] = config["params"]||{};
      config["data"]["aliid"] = sessionStorage.getItem("aliid")||"" // 阿里id
      config["data"]["shop_id"] = sessionStorage.getItem("shop_id")||"" // 店铺id

      if(!config["data"].NoShowLoading){
        loading_list[config.url] = window.GlobalLoading.show()
      }

      delete config["params"];
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 3.响应拦截器
service.interceptors.response.use(
  response => {
    window.GlobalLoading.close(loading_list[response.config.url])
    delete loading_list[response.config.url]
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    return response.data;
  },
  error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
    } else {
      // 超时处理
    }
    setTimeout(() => {
      window.Toast.show("当前网络不佳，请刷新页面或切换网络后查看");
    }, 1000);
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  });
//4.导入文件
export default service;
