export default {
  small_shop_trusteeship_split_line: {
    background: "#EFF0F7",
    width: "100vw",
    height: "2.6vw",
    minHeight: "2.6vw"
  },
  swiper_ontent: {
    display: "flex",
    height: "calc(100vh - 28vw)",
    overflow: "auto",
    boxSizing: "border-box",
  },

  // 自动铺货日志
  small_bell_white: {
    width: "3.5vw",
    height: "4vw",
    marginTop: "0.5vw",
  },
  top_box: {
    background: "#70ADFF",
    padding: "2.66vw 2.6vw 2.66vw 3.6vw",
    position: ""
  },
  top_title: {
    color: "#FFFFFF",
    fontSize: "3.73vw",
    fontWeight: "700",
    marginLeft: "1.86vw",
  },
  top_text: {
    fontSize: "3.45vw",
    lineHeight: "5.33vw",
    color: "#FFFFFF",
  },
  goods_info_title: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4.8vw 4vw 3.33vw",
  },
  info_title: {
    fontSize: "3.73vw",
    color: "#575757",
  },
  see_all_button: {
    fontSize: "3.73vw",
    color: "#3179D0",
    display: "flex",
    alignItems: "center",
  },
  see_all_icon: {
    width: "2.8vw",
    height: "1.73vw",
    display: "inline-block",
    marginLeft: "1vw",
    position: "relative",
    top: "0.1vw",
  },
  goods_info_list: {
    padding: "0vw 0vw 0vw 4vw",
    position: "relative",
    flex: "1",
  },
  goods_item: {
    display: "flex",
    borderBottom: "1px solid #EFEFEF",
    padding: "4vw 4vw 4vw 0vw"
  },
  goods_item_img: {
    width: "17.33vw",
    height: "17.33vw",
    marginRight: "2.66vw",
    borderRadius: "1vw",
    border: "1px solid #EFEFEF",
  },
  goods_item_info_box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  goods_item_title: {
    color: "#2E3136",
    fontSize: "3.73vw",
    lineHeight: "5.5vw",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    textOverflow: "ellipsis",
  },
  goods_item_time: {
    fontSize: "2.93vw",
    color: "#9C9C9C",
  },
  footer_box: {
    width: '100%',
    height: "18vw",
    boxShadow: "0px -4px 10px -4px rgba(179, 179, 179, 0.25)",
    position: 'fixed',
    bottom: '0',
    background: '#ffffff',
    padding: "0vw 3.3vw",
    boxSizing: 'border-box',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer_button: {
    width: "44.8vw",
    height: "11.46vw",
    borderRadius: "12vw",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4vw',
  },
  footer_button_1: {
    color: "#000000",
    background: "#FFFFFF",
    border: "1px solid #000000",
  },
  footer_button_2: {
    color: "#ffffff",
    background: "#FC3928",
    border: "1px solid #FC3928",
  },
  footer_button_icon: {
    width: "4.2vw",
    height: "4.2vw",
    marginRight: "1vw",
  },
  goods_info_mask_box: {
    position: "absolute",
    left: "0vw",
    top: "0vw",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  goods_info_mask:{
    width: "100%",
    flex: "1",
    background: "rgba(0,0,0,0.7)",
  },
  push_goods_log_line:{
    width: "92vw",
    background: "#F5F5F5",
    height: "1px",
    margin: "0vw 4vw",
  },
  push_goods_log: {
    background: "#ffffff",
    padding: "4.4vw",
  },
  push_goods_log_title: {
    color: "#0D0D0D",
    fontSize: "4vw",
  },
  push_goods_log_list: {
    maxHeight: "50vw",
    overflow: "auto",
  },
  push_goods_log_item: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "6vw",
  },
  push_goods_log_text: {
    fontSize: "3.73vw",
    color: "#585858",
  },

  // 替换滞销品日志
  unsalable_goods_title: {
    display: "flex",
    fontSize: "3.73vw",
    padding: "4vw",
    alignItems: "center",
    height: "12vw",
    boxSizing: "border-box",
  },
  unsalable_goods_title_tips_icon: {
    width: "4vw",
    height: "4vw",
    marginRight: "2vw",
  },
  unsalable_goods_img: {
    width: "15.4vw",
    height: "15.4vw",
    marginRight: "2.4vw",
    borderRadius: "1vw",
    border: "1px solid #EFEFEF",
  },
  unsalable_goods_item: {
    display: "flex",
    padding: "2.8vw 4vw 4.26vw 4vw",
  },
  unsalable_goods_list_line: {
    height: "1px",
    position: "relative",
    background: "#EFEFEF",
    marginLeft: "4vw",
  },
  unsalable_goods_list_time: {
    position: "absolute",
    height: "2px",
    lineHeight: "1px",
    background: "#ffffff",
    padding: "0vw 2.66vw",
    left: "26vw",
    color: "#9C9C9C",
  },
  bottom_tips_box: {
    position: "absolute",
    top: "-16vw",
    left: "0vw",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  no_data_block: { 
    height: "100%", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    color: "#999999",
    fontSize: "3.73vw"
  },
  unsalable_goods_item_text: {
    marginTop:"2.6vw",
    color:"#2E3136",
    fontSize:"3.46vw",
    maxWidth:"73.33vw",
    textOverflow:"ellipsis",
    overflow:"hidden",
    whiteSpace:"nowrap",
  },
  getReplaceLog_split_line: {
    background: "#EFF0F7",
    height: "2.6vw",
    minHeight:"1px",
    marginLeft:'4vw',
  },
}