import React from "react";
import { Switch } from 'antd-mobile';
export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Switch
        checked={this.props.value} 
        onChange={this.props.onValueChange}
        style={{
          '--checked-color': '#FC3928',
          '--height': '6.2vw',
          '--width': '12.8vw',
        }}
      />
    )
  }
}
