import React from "react";
import Style from "./style";
import { OSS } from "@/utils/Public";
import { Text } from "@/UI";
import PublicQuery from "@/API/public";
import DrawImage from "@/utils/DrawImage";

export default class Index extends React.Component {
  constructor(props) {
    document.title = '绑店'
    super(props);
    let getQrcode = sessionStorage.getItem("getQrcode")
    getQrcode = getQrcode?JSON.parse(getQrcode):{};

    this.state = {
      getQrcode,
      qrcode_oss:"",
      show_error_tips_dialog: false,
      show_success_tips_dialog: false,
    }
  }
  componentDidMount() {
    PublicQuery("visitRecord",{
      page_name: "autoPush_authorizePddPage",
      control: "autoPush_authorizePddPage_in"
    })
    DrawImage( this.state.getQrcode.qrcode_oss, OSS + "PDD_qrcode_scan_twice_icon.png").then(res=>{
      if(res){
        this.setState({qrcode_oss: res})
      }
    })
  }
  lang_touch_down_FN(){
    this.lang_touch_timer = true
    setTimeout(()=>{
      if(this.lang_touch_timer){
        PublicQuery("visitRecord",{
          page_name: "autoPush_authorizePddPage",
          control: "autoPush_saveQrCode_hold"
        })
      }
    },500)
  }
  lang_touch_up_FN(){
    this.lang_touch_timer = false
  }
  render() {
    return (
      <>
        <div style={Style.build_shop_body}>
          <div style={Style.build_shop_body_title}>
            <div style={Style.build_shop_body_title_icon}></div>
            暂不支持，微信扫码
          </div>
          <div style={Style.build_shop_Tips}>
            <div style={Style.build_shop_Tips_item}>
              <div style={Style.build_shop_Tips_icon}></div>
              本页截屏或长按保存至手机
            </div>
            <div style={Style.build_shop_Tips_item}>
              <div style={Style.build_shop_Tips_icon}></div>
              用 <Text style={{color:"#FC3928",fontWeight:"bold",fontSize:"3.9vw"}}>拼多多商家版，扫码2次</Text> 完成绑店
            </div>
          </div>
          <div style={Style.build_shop_qrcode_box}>
            {this.state.qrcode_oss && <>
              <img onTouchStart={this.lang_touch_down_FN.bind(this)} onTouchEnd={this.lang_touch_up_FN.bind(this)} style={Style.save_qrcode_img} src={ this.state.qrcode_oss } />
              <div style={Style.build_shop_qrcode_image_box}>
                <img style={Style.build_shop_qrcode_image} src={ this.state.qrcode_oss } />
              </div>
              <div style={Style.build_shop_qrcode_text}>长按选择保存到手机</div>
            </>}
          </div>
        </div>
      </>
    )
  }
}