import React from "react";
import Style from "./style";
import {Mask} from "@/UI";

export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return (
      <Mask>
        <div style={Style.box}>
          <div style={Style.body}>{this.props.children}</div>
          <div style={Style.button_box}>
            <div style={Style.button_cancel} onClick={()=>{this.props.onCancel()}}>
              {this.props.Cancel||"取消"}
            </div>
            <div style={Style.button_confirmation} onClick={()=>{this.props.onConfirmation()}}>
              {this.props.Confirmation||"确定"}
            </div>
          </div>
        </div>
      </Mask>
    )
  }
}

