export default {
  true_icon:{
    width: "10.66vw",
    height: "10.66vw",
    margin: "7vw auto 3.2vw",
  },
  top_tips_box:{
    width: "90vw",
    margin: "0 auto 3.2vw",
    padding: "3.2vw",
    background: "#F8F8F8",
    border: "1px solid #EDEDED",
    borderRadius: "2vw",
  },
  top_tips_item:{
    textAlign: "center",
    fontSize: "3.46vw",
    lineHeight: "6.4vw",
  },
  top_tips_left:{
    color: "#27AE60",
    fontSize: "3.46vw",
    width: "14vw",
  },
  top_tips_right:{
    fontSize: "3.46vw",
    width: "71vw",
  },
  tips_title:{
    background: "#FFDFDC",
    height: "11.73vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1.33vw 1.33vw 0vw 0vw",
    fontSize: "4.26vw",
    color: "#FC3928",
    fontWeight: "bold",
    marginTop: "8vw",
  },
  tips_box:{
    background:"#ffffff",
    padding: "7.5vw 3.2vw 18.4vw 3.2vw",
    borderRadius: "0vw 0vw 1.33vw 1.33vw",
  },
  tips_content_item:{
    display: "flex",
    marginBottom: "4.66vw",
  },
  tips_content_left:{
    color: "#27AE60",
    marginRight: "1vw",
    fontSize: "3.46vw",
    fontWeight: "bold",
    lineHeight: "6.4vw",
    flexShrink: "0",
  },
  tips_content_right:{
    fontSize: "3.46vw",
    lineHeight: "6.4vw",
  },
  tips_content_button:{
    width: "53vw",
    height: "10.66vw",
    borderRadius: "10.66vw",
    color: "#FC3928",
    border: "1px solid #FC3928",
    fontSize: "3.73vw",
    fontWeight: "bold",
    margin: "12.26vw auto 0vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  mask_box:{
    position: 'fixed',
    bottom: '0vw',
    width: '100vw',
    background: '#ffffff',
    borderRadius: '4vw 4vw 0 0',
    padding: '7.2vw 4.5vw',
    boxSizing: "border-box",
  },
  mask_box_title:{
    fontSize: '4.3vw',
    fontWeight: '700',
    textAlign: 'center',
    color: '#333333',
    marginTop: "1vw",
    marginBottom: "8.5vw",
    position: "relative",
  },
  mask_box_close:{
    width: "4.25vw",
    height: "4.25vw",
    position: 'absolute',
    top: '4vw',
    right: '4vw',
  },
  mask_box_item:{
    display: 'flex',
    fontWeight: 'bold',
  },
  mask_box_text:{
    fontSize: '4vw',
    lineHeight: '5.33vw',
  },
  mask_box_button:{
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: "14.4vw auto 0vw",
    width: "68.53vw",
    height: "12vw",
    background: "#2F80ED",
    borderRadius: "9vw",
    fontSize: "4.26vw",
    color: "#FFFFFF",
  },
}