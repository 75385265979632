export default {
  box:{
    width: "96vw",
    height: "12.8vw",
    background: "rgba(0,0,0,0.7)",
    borderRadius: "0.8vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 3.5vw",
    boxSizing: "border-box",
    color: "#fff",
  },
  button:{
    width: "20.2vw",
    height: "7.46vw",
    borderRadius: "7.46vw",
    background: "#2279FC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "3.2vw",
  },
}