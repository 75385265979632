import React from "react";
import Style from "./style";
import { OSS } from "@/utils/Public";
import { InfiniteScroll } from "@/UI";
import Dialog2 from "@/Component/dialog2";
import PushGoodsLog from "@/API/push_goods_log";
import image_replace_300x300 from "@/utils/image_replace_300x300";

export default class Index extends React.Component{
  constructor(props){
    document.title = "滞销商品替换"
    super(props);
    let host_setting = sessionStorage.getItem("host_setting")
    host_setting = host_setting? JSON.parse(host_setting):{}
    this.state={
      show_page: false,
      hasMore: false,
      replace_goods: false, // 确认替换弹窗
      no_replace_goods: false, // 确认不替换弹窗
      replace_goods_OK: false, // 替换完成
      outside_id : host_setting.outside_id,
      data: [],
    }
  }
  componentDidMount(){
    this.getDataParams = {
      page: 1,
      outside_id: this.state.outside_id,
    }
    this.getDataFN().then(res=>{
      this.setState({
        show_page: true,
        data:res.data,
      })
    })
  }
  getDataFN(){
    return new Promise(async (resolve, reject)=>{
      let res = {}
      res = await PushGoodsLog("confirmReplace",this.getDataParams)
      if(!res.data || res.data.length==0){
        this.setState({ hasMore: false })
      }else{
        this.setState({ hasMore: true })
      }
      resolve(res)
    })
  }
  loadMore() {
    return new Promise(async (resolve)=>{
      this.getDataParams.page += 1
      let res = await this.getDataFN()
      let data = this.state.data
      data = data.concat(res.data)
      this.setState({ data })
      resolve("---懒加载触发")
    })
  }
  render_unsalable_goods_list (){
    let elm = new Array()
    let data = this.state.data
    data.map((item,index)=>{
      let push_image = image_replace_300x300(item.push_images)
      let replace_image = image_replace_300x300(item.replace_images)

      elm.push(<div style={Style.unsalable_goods_list} key={"render_unsalable_goods_list"+index}>
        <div style={Style.unsalable_goods_item}>
          <img src={replace_image} style={Style.unsalable_goods_img} />
          <div>
            <div style={{marginTop:"1.6vw",color:"#FC3928",fontSize:"3.46vw"}}>滞销品</div>
            <div style={Style.unsalable_goods_item_text}>{item.replace_title}</div>
          </div>
        </div>
        <div style={Style.unsalable_goods_list_line}>
          <div style={Style.unsalable_goods_list_time}>商品替换</div>
        </div>
        <div style={Style.unsalable_goods_item}>
          <img src={push_image} style={Style.unsalable_goods_img} />
          <div>
            <div style={{marginTop: "1.6vw",color:"#0EAC50",fontSize:"3.46vw"}}>替换品</div>
            <div style={Style.unsalable_goods_item_text}>{item.push_title}</div>
          </div>
        </div>
        <div style={{...Style.replace_unsalable_goods_split_line,height:"1.33vw",minHeight:"1.33vw"}}></div>
      </div>)
    })
    return elm
  }

  close_dialog(key){
    this.setState({[key]:false})
  }
  Confirmation(is_agree){
    let params = {
      is_agree,
      outside_id : this.state.outside_id,
    }
    PushGoodsLog("confirmReplace",params).then(res=>{
      console.log(res,'---res')
      if(res.code == 0){
        sessionStorage.setItem("small_shop_trusteeship_activeIndex","1")
        if(is_agree=="1"){
          this.close_dialog("replace_goods")
          this.setState({replace_goods_OK:true})
        }else{
          this.close_dialog("no_replace_goods")
          window.location.href = "/small_shop_trusteeship"
        }
      }else{
        res.message && window.Toast.show(res.message)
      }
    })
  }
  replace_goods_OK_bottom(){
    window.location.replace("/small_shop_trusteeship")
  }

  render(){
    return(<div style={{overflow:"auto",height:"100vh"}}>
      {this.state.show_page &&<>
        {/* 弹窗 */}
        {this.state.replace_goods && <Dialog2
            onCancel={this.close_dialog.bind(this,"replace_goods")}
            onConfirmation={this.Confirmation.bind(this,"1")}
            hasCancel={true}
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"23.5vw", paddingTop:"9vw" }}>
              <div style={Style.Dialog2_text}>是否确定替换滞销商品</div>
              <div style={Style.Dialog2_text}>确定后不可撤销</div>
            </div>
        </Dialog2>}
        {this.state.no_replace_goods &&  <Dialog2
            onCancel={this.close_dialog.bind(this,"no_replace_goods")}
            onConfirmation={this.Confirmation.bind(this,"-1")}
            hasCancel={true}
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"29.6vw", paddingTop:"9vw" }}>
              <div style={Style.Dialog2_text}>是否确定不同意替换</div>
              <div style={Style.Dialog2_text}>确定后需替换的商品信息</div>
              <div style={Style.Dialog2_text}>将不再保留</div>
            </div>
        </Dialog2>}
        {this.state.replace_goods_OK && <div style={Style.replace_goods_OK_box}>
          <img src={OSS+"true_icon.png"} style={Style.replace_goods_OK_img}/>
          <div  style={{...Style.replace_goods_OK_text}}>滞销品正在陆续替换中，请稍后查看……</div>
          <div onClick={this.replace_goods_OK_bottom.bind(this)} style={Style.replace_goods_OK_bottom}>我知道了</div>
        </div>}
        {!this.state.replace_goods_OK && <div>
          <div style={Style.unsalable_goods_box}>
            <div style={Style.unsalable_goods_title} >
              <img style={Style.unsalable_goods_title_tips_icon} src={OSS+"tips_icon_white.png"} />
              请确认是否替换以下滞销品，在“代运营设置”中开启允许自动更换后，下次将自动完成替换无需确认
            </div>
            <div style={Style.replace_unsalable_goods_split_line}></div>
            <div style={{flex:"1",overflow:"auto"}}>
              {this.render_unsalable_goods_list()}
              <InfiniteScroll loadMore={this.loadMore.bind(this)} hasMore={this.state.hasMore} />
            </div>
          </div>
          <div style={Style.footer_box}>
            <div onClick={()=>{this.setState({no_replace_goods:true})}} style={{...Style.footer_button,...Style.footer_button_1}}>不同意替换</div>
            <div onClick={()=>{this.setState({replace_goods:true})}} style={{...Style.footer_button,...Style.footer_button_2}}>同意替换</div>
          </div>
        </div>}
      </>}
    </div>)
  }
}