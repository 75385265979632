export default {
  box:{
    position: 'Fixed',
    bottom:"0",
    width: '100vw',
    background: '#FFFFFF',
    borderRadius: '3.2vw 3.2vw 0 0',
    paddingBottom: "10vw",
  },
  close:{
    width:"4.2vw",
    height:"4.2vw",
    position:"absolute",
    top:"4.8vw",
    right:"4.8vw",
  },
  scan_alibaba_image:{
    width: "62vw",
    height: "21.33vw",
    margin: "5.73vw auto 0vw",
  },
  scan_alibaba_text:{
    color: "#333333",
    fontSize: "4.2vw",
    lineHeight: "9.2vw",
    textAlign: "center",
    fontWeight: "bold",
  },
  scan_alibaba_qrcode_box:{
    width: "56.66vw",
    height: "56.66vw",
    borderRadius: "1.33vw",
    border: "1px solid #E0E0E0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "4vw auto 0vw",
  },
  scan_alibaba_qrcode:{
    width: "51.7vw",
    height: "51.7vw",
  },
  save_qrcode_button:{
    width: "92.5vw",
    height: "11.5vw",
    color: "#ffffff",
    background: "#FC3928",
    border: "1px solid #FC3928",
    boxSizing: "border-box",
    borderRadius: "10.7vw",
    fontSize: "4.25vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8.3vw auto 3.7vw",
  },
}