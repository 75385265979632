import React from "react";
import Style from "./style";
import { Mask, Switch, Radio, Toast } from "@/UI";
import {OSS} from "@/utils/Public";
import PublicQuery from "@/API/public";
import Dialog from "@/Component/dialog";

export default class Index extends React.Component{
  constructor(props){
    super(props);
    let host_setting = sessionStorage.getItem('host_setting')
    host_setting = host_setting?JSON.parse(host_setting):{}
    this.state = {
      host_setting,
      show_off_host_switch_dialog: false,
      save_tips_dialog: false,
    }
  }
  SwitchChange(value){
    if(!value){
      this.setState({show_off_host_switch_dialog:true})
    }else{
      let host_setting = this.state.host_setting
      host_setting.host_switch = value?"1":"0"
      this.setState({ host_setting })
    }
  }
  off_host_switch_FN(){
    let host_setting = this.state.host_setting
    host_setting.host_switch = "0"
    this.setState({
      host_setting,
      show_off_host_switch_dialog:false,
    })
  }
  change_radio(value,key){
    let host_setting = this.state.host_setting
    host_setting[key] = value
    this.setState({host_setting})
  }
  save_button(){
    sessionStorage.setItem('host_setting',JSON.stringify(this.state.host_setting))
    PublicQuery("saveHostingSetting", this.state.host_setting).then(res=>{
      if(res.code==0){
        this.setState({save_tips_dialog:true})
      }else{
        window.Toast.show(res.data.msg)
      }
    })
  }
  save_tips_dialog_FN(){
    this.setState({save_tips_dialog:false})
    this.props.onSave(this.state.host_setting)
  }
  render(){
    return(
      <>
        <Mask>
          <div style={Style.set_box}>
            <div style={Style.set_box_title}>
              设置人工代运营的商品条件
              <div onClick={()=>{this.props.onClose()}} style={Style.set_box_close_box}>
                <img style={Style.set_box_close} src={OSS+"close_999999.png"} />
              </div>
            </div>
            {!this.props.hidden_header && <div style={Style.set_box_auto_manage}>
              <div style={Style.set_box_auto_manage_title}>
                关闭人工代运营
                <div style={Style.set_box_auto_manage_title_switch}>
                  <Switch value={this.state.host_setting.host_switch==1} onValueChange={this.SwitchChange.bind(this)}></Switch>
                </div>
              </div>
              <div style={Style.set_box_auto_manage_title_tips}>关闭后将不再提供人工代运营服务，且无法再次开启请谨慎选择</div>
            </div>}
            <div style={{...Style.set_box_item_box,border:"none"}}>
              <div style={Style.set_box_item_name} >每日人工上架</div>
              <div custom_style="custom_style_radio" style={Style.set_box_item_content}>
                <Radio.Group
                  value={this.state.host_setting.daily_sale_type}
                  onChange={val=>{this.change_radio(val,"daily_sale_type")}}
                >
                  <Radio value='1' style={{'--icon-size': '4.2vw',width:"100%",marginBottom:"4vw"}}><div style={{fontSize:"3.2vw"}}>同行日销100单以下商品</div></Radio>
                  <Radio value='2' style={{'--icon-size': '4.2vw',width:"100%"}}><div style={{fontSize:"3.2vw"}}>同行日销100单以上商品</div></Radio>
                </Radio.Group>
              </div>
            </div>
            <div style={Style.set_box_item_box}>
              <div style={Style.set_box_item_name} >商品最快成交周期</div>
              <div custom_style="custom_style_radio" style={Style.set_box_item_content}>
                <Radio.Group
                  value={this.state.host_setting.deal_cycle}
                  onChange={val=>{this.change_radio(val,"deal_cycle")}}
                >
                  <Radio value='3' style={{'--icon-size': '4.2vw',width:"50%"}}><div style={{fontSize:"3.2vw"}}>3日</div></Radio>
                  <Radio value='7' style={{'--icon-size': '4.2vw',width:"50%"}}><div style={{fontSize:"3.2vw"}}>7日</div></Radio>
                </Radio.Group>
              </div>
            </div>
            {/* <div style={Style.set_box_item_box}>
              <div style={Style.set_box_item_name} >人工更换滞销品</div>
              <div custom_style="custom_style_radio" style={Style.set_box_item_content}>
                <Radio.Group
                  value={this.state.host_setting.auto_replace_unsold}
                  onChange={val=>{this.change_radio(val,"auto_replace_unsold")}}
                >
                  <Radio value='1' style={{'--icon-size': '4.2vw',width:"50%"}}><div style={{fontSize:"3.2vw"}}>开启</div></Radio>
                  <Radio value='0' style={{'--icon-size': '4.2vw',width:"50%"}}><div style={{fontSize:"3.2vw"}}>不开启</div></Radio>
                </Radio.Group>
              </div>
            </div> */}
            <div style={Style.set_box_item_box}>
              <div style={Style.set_box_item_name} >商品退货率</div>
              <div custom_style="custom_style_radio" style={Style.set_box_item_content}>
                <Radio.Group
                  value={this.state.host_setting.return_ratio}
                  onChange={val=>{this.change_radio(val,"return_ratio")}}
                >
                  <Radio value='0.005' style={{'--icon-size': '4.2vw',width:"50%"}}><div style={{fontSize:"3.2vw"}}>0.5%以下</div></Radio>
                  <Radio value='0.008' style={{'--icon-size': '4.2vw',width:"50%"}}><div style={{fontSize:"3.2vw"}}>0.8%以下</div></Radio>
                </Radio.Group>
              </div>
            </div>
            <div style={Style.set_box_item_box}>
              <div style={Style.set_box_item_name} >商品售价设置</div>
              <div custom_style="custom_style_radio" style={Style.set_box_item_content}>
                <Radio.Group
                  value={this.state.host_setting.goods_price_type}
                  onChange={val=>{this.change_radio(val,"goods_price_type")}}
                >
                  <Radio value='1' style={{'--icon-size': '4.2vw',width:"100%",marginBottom:"4vw"}}><div style={{fontSize:"3.2vw"}}>比其他商家低10%</div></Radio>
                  <Radio value='2' style={{'--icon-size': '4.2vw',width:"100%",marginBottom:"4vw"}}><div style={{fontSize:"3.2vw"}}>比其他商家高10%</div></Radio>
                  <Radio value='0' style={{'--icon-size': '4.2vw',width:"100%"}}><div style={{fontSize:"3.2vw"}}>不设置</div></Radio>
                </Radio.Group>
              </div>
            </div>
            <div onClick={this.save_button.bind(this)} style={Style.set_box_button}>确认设置</div>
          </div>
        </Mask>
        {this.state.show_off_host_switch_dialog &&
          <Dialog
            onCancel={()=>{this.setState({show_off_host_switch_dialog:false})}}
            onConfirmation={this.off_host_switch_FN.bind(this)}
            Cancel="取消"
            Confirmation="确认"
            hasCancel={true}
            title="关闭人工代运营"
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height: "22vw" }}>
              <div style={{fontSize:"3.73vw"}}>是否确认关闭，关闭后将不再提供</div>
              <div style={{fontSize:"3.73vw"}}>人工代运营服务，并且无法再次开启，</div>
              <div style={{fontSize:"3.73vw"}}>请谨慎选择</div>
            </div>
          </Dialog>
        }
        {this.state.save_tips_dialog &&
          <Dialog
            onConfirmation={this.save_tips_dialog_FN.bind(this)}
            Confirmation="我知道了"
            title="保存成功"
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"10vw",fontSize:"3.73vw" }}>
              <div>已设置完成</div>
            </div>
        </Dialog>}
      </>
    )
  }
}