import React from "react";
import Style from "./style";
import { OSS, Root } from "@/utils/Public";
import { Text } from "@/UI";
import Dialog from "@/Component/dialog";
import PublicQuery from "@/API/public";
import DrawImage from "@/utils/DrawImage";

export default class Index extends React.Component {
  constructor(props) {
    document.title = '绑店'
    super(props);
    let getQrcode = sessionStorage.getItem("getQrcode")
    getQrcode = getQrcode?JSON.parse(getQrcode):{};

    this.state = {
      getQrcode,
      qrcode_oss:"",
      show_tips_dialog: false,
      show_inquiry_dialog: false,
    }
  }
  componentDidMount() {
    PublicQuery("visitRecord",{
      page_name: "autoPush_bindShopPage",
      control: "autoPush_bindShopPage_in"
    })
    DrawImage( this.state.getQrcode.qrcode_oss, OSS + "qrcode_scan_twice_icon.png").then(res=>{
      if(res){
        this.setState({qrcode_oss: res})
      }
    })
  }
  build_shop_done(){
    PublicQuery("visitRecord",{
      page_name: "autoPush_bindShopPage",
      control: "autoPush_hasBindShop_click"
    })
    // 查询绑店状态
    PublicQuery("checkBindShop").then(res=>{
      if(res.code == "0"){
        let status = sessionStorage.getItem("status")
        status = status?JSON.parse(status):{};
        status.shop_name = res.data.shop_name
        sessionStorage.setItem("status",JSON.stringify(status))
        window.location.href = Root+"/first_entry"
      }else{
        this.setState({show_tips_dialog:true})
      }
      this.setState({show_inquiry_dialog:false})
    });
  }
  lang_touch_down_FN(){
    this.lang_touch_timer = true
    setTimeout(()=>{
      if(this.lang_touch_timer){
        PublicQuery("visitRecord",{
          page_name: "autoPush_bindShopPage",
          control: "autoPush_saveQrCode_hold"
        })
      }
    },500)
  }
  lang_touch_up_FN(){
    this.lang_touch_timer = false
  }
  render() {
    return (
      <>
        {this.state.show_tips_dialog &&
          <Dialog
            onConfirmation={()=>{this.setState({show_tips_dialog:false})}}
            Confirmation="我知道了"
            title="绑店失败"
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"26vw",fontSize:"3.73vw" }}>
              <div>系统检查您未完成绑店</div>
              <div>请用拼多多商家版扫码2次完成绑店后</div>
              <div>再进行人工代运营操作</div>
            </div>
        </Dialog>}
        
        <div style={Style.build_shop_top_box}>
          <img style={Style.build_shop_top_background} src={OSS + "build_shop_top_background.png"} />
          <div style={Style.build_shop_top_text}>您的拼多多小店还未绑定，请按以下方式完成绑定</div>
        </div>
        <div style={Style.build_shop_split_line}></div>
        <div style={Style.build_shop_body}>
          <div style={Style.build_shop_body_title}>
            <div style={Style.build_shop_body_title_icon}></div>
            用<Text style={Style.build_shop_body_title_name}>拼多多商家版</Text>扫下方二维码2次
          </div>
          <div style={Style.build_shop_Tips}>
            <div style={Style.build_shop_Tips_item}>
              <div style={Style.build_shop_Tips_icon}></div>
              第一次扫码：点击“去续费”订购分销宝
            </div>
            <div style={Style.build_shop_Tips_item}>
              <div style={Style.build_shop_Tips_icon}></div>
              第二次扫码：订购成功后完成授权确认
            </div>
          </div>
          <div style={Style.build_shop_qrcode_box}>
            {this.state.qrcode_oss && <>
              <img onTouchStart={this.lang_touch_down_FN.bind(this)} onTouchEnd={this.lang_touch_up_FN.bind(this)} style={Style.save_qrcode_img} src={ this.state.qrcode_oss } />
              <div style={Style.build_shop_qrcode_image_box}>
                <img style={Style.build_shop_qrcode_image} src={ this.state.qrcode_oss } />
              </div>
              <div style={Style.build_shop_qrcode_text}>长按选择保存到手机</div>
            </>}
          </div>
          <div onClick={this.build_shop_done.bind(this)} style={Style.build_shop_button}>已绑店开始铺货</div>
        </div>
      </>
    )
  }
}