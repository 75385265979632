import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./Redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css"
import App from "./App";
import FirstEntry from './Pages/first_entry';
import AuthorizeShop from './Pages/authorize_shop';
import BuildShop from './Pages/build_shop';
import PushGoodsDone from './Pages/push_goods_done';
import HowSetTemplate from './Pages/how_set_template';
import SmallShopTrusteeship from './Pages/small_shop_trusteeship';
import ReplaceUnsalableGoods from './Pages/replace_unsalable_goods';
import OrderProce from './Pages/order_proce';
import WxQrcodeToPddScan from './Pages/wx_qrcode_to_pdd_scan';
import GetURLParams from './utils/GetURLParams';

import { WebOS } from "./utils/Public";
import get_token from "./utils/get_token";
import updateAppMessageShareData from './utils/updateAppMessageShareData';

console.log("当前版本：绑定店铺版");
const regular = {
  code: /[?&]code=[^&]*&?/g,
  token: /[?&]token=[^&]*&?/g,
}

if (GetURLParams('token')) { // URL中存在token参数
  sessionStorage.setItem("x-token", GetURLParams('token'))
  let URL = window.location.pathname + window.location.search + window.location.hash
  window.history.replaceState('', '', URL.replace(regular['token'], "&"))
  init()
} else if (WebOS == "wx" && !sessionStorage.getItem("x-token")) { // 微信环境下无token则跳转到授权页面
  get_token().then(res => {
    // 删除url中特殊字段
    let URL = window.location.pathname + window.location.search + window.location.hash
    window.history.replaceState('', '', URL.replace(regular['code'], "&"))
    init()
  })
} else {
  init()
}

function init() {
  updateAppMessageShareData() // 修改分享链接
  const rootElement = document.getElementById("root");
  render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="first_entry" element={<FirstEntry />} />
            <Route path="build_shop" element={<BuildShop />} />
            <Route path="authorize_shop" element={<AuthorizeShop />} />
            <Route path="push_goods_done" element={<PushGoodsDone />} />
            <Route path="how_set_template" element={<HowSetTemplate />} />
            <Route path="small_shop_trusteeship" element={<SmallShopTrusteeship />} />
            <Route path="replace_unsalable_goods" element={<ReplaceUnsalableGoods />} />
            <Route path="order_proce" element={<OrderProce />} />
            <Route path="wx_qrcode_to_pdd_scan" element={<WxQrcodeToPddScan />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
}
