export default function DrawImage(img1, img2) {
  return new Promise((resolve, reject) => {
    if (img1 && img2) {
      let time_stamp = new Date().getTime()
      let image = new Image();
      image.src = `${img1}?v=${time_stamp}`;    //参数防止缓存图片
      image.crossOrigin = 'anonymous';
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = 465;
        canvas.height = 465;
        let context = canvas.getContext("2d");
        context.rect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "#fff";
        context.fill();
        context.drawImage(image, 0, 0, 465, 465);
        let image2 = new Image();
        image2.src = `${img2}?v=${time_stamp}`;   //参数防止缓存图片
        image2.crossOrigin = 'anonymous';
        image2.onload = function () {
          context.drawImage(image2, 175, 175, 127, 127);
          let base64 = canvas.toDataURL("image/png");  //"image/png" 这里注意一下
          resolve(base64);
        }
      }
    } else { 
      resolve(false);
    }
  })
}