import React from "react";
import Style from "./index.module.css";
import Mask from "../Mask";
import { OSS } from "../../../utils/Public";

export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return (
      <Mask>
        <div className={Style.box}>
          <div className={Style.loading_box}>
            <img className={Style.loading_item} src={OSS+"loading_white.png"} />
            {this.props.text && <div className={Style.loading_text}>{this.props.text}</div>}
          </div>
        </div>
      </Mask>
    )
  }
}