export default {
  set_box:{
    position: 'fixed',
    bottom: '0vw',
    width: '100vw',
    background: '#ffffff',
    borderRadius: '4vw 4vw 0 0',
    padding: '2.5vw',
    boxSizing: "border-box",
  },
  set_box_title:{
    fontSize: '4.3vw',
    textAlign: 'center',
    color: '#333333',
    margin: "2vw 0vw",
    position: "relative",
  },
  set_box_close_box:{
    width: "8vw",
    height: "8vw",
    position: 'absolute',
    top: '-1.5vw',
    right: '0vw',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  set_box_close:{
    width: "4.25vw",
    height: "4.25vw",
  },
  set_box_auto_manage:{
    background:  'rgba(207, 129, 129, 0.08)',
    mixBlendMode: 'normal',
    padding: "3.5vw",
    marginTop: "4.66vw",
  },
  set_box_auto_manage_title:{
    position: "relative",
    fontSize: '4.3vw',
    color: "#000000",
    fontWeight: 'bold',
  },
  set_box_auto_manage_title_switch:{
    position: 'absolute',
    top: "0vw",
    right: "0vw",
  },
  set_box_auto_manage_title_tips:{
    color: '#FC3928',
    fontSize: "3.5vw",
    marginTop: "2.66vw",
  },
  set_box_item_box:{
    padding: "4vw 0vw 4vw 3.5vw",
    display: "flex",
    borderTop: "1px solid #EFEFEF",
  },
  set_box_item_name:{
    width:"33vw",
    fontSize: '3.2vw',
    verticalAlign: "top",
    color: "#000000"
  },
  set_box_item_content:{
    flex: '1',
    verticalAlign: "top",
    color: "#494C58",
  },
  set_box_button:{
    background: "#FC3928",
    height: "10.66vw",
    color: "#FFFFFF",
    fontSize: "4.26vw",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10.66vw',
  },
}