import { InfiniteScroll } from 'antd-mobile'
import React from "react";

export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <InfiniteScroll loadMore={this.props.loadMore} hasMore={this.props.hasMore} />
    )
  }
}