import React from "react";
import Style from "./style";
import { OSS, Root } from "@/utils/Public";
import { Text, InfiniteScroll } from "@/UI";
import PushGoodsLog from "@/API/push_goods_log";
import image_replace_300x300 from "@/utils/image_replace_300x300";

export default class Index extends React.Component{
  constructor(props){
    super(props);
    let has_remote_template = sessionStorage.getItem("has_remote_template")
    this.state={
      show_page: false,
      hasMore: false,
      show_log: false,
      getPushLog: {},
      has_remote_template,
    }
  }
  componentDidMount(){
    let host_setting = sessionStorage.getItem("host_setting")
    host_setting = host_setting? JSON.parse(host_setting):{}
    this.getDataParams = {
      date:"",
      page:1,
      outside_id : host_setting.outside_id,
    }
    this.getDataFN().then(res=>{
      this.setState({ 
        getPushLog:res.data||{},
        show_page: true,
      })
    })
  }
  getDataFN(){
    return new Promise(async (resolve, reject)=>{
      let res = {}
      res = await PushGoodsLog("getPushLog",this.getDataParams)
      if(!res.data.push_data || res.data.push_data.length==0){
        this.setState({ hasMore: false })
      }else{
        this.setState({ hasMore: true })
      }
      resolve(res)
    })
  }
  async getLogData(date){
    this.getDataParams.date = date
    this.getDataParams.page = 1
    let res = await this.getDataFN()
    this.setState({
      show_log:false,
      getPushLog:res.data||{}
    })
  }
  loadMore() {
    return new Promise(async (resolve)=>{
      this.getDataParams.page += 1
      let res = await this.getDataFN()
      let getPushLog = this.state.getPushLog
      getPushLog.push_data = getPushLog.push_data.concat(res.data.push_data)
      this.setState({ getPushLog })
      resolve("---懒加载触发")
    })
  }
  render_goods_item(){
    let elm = new Array()
    let push_data = this.state.getPushLog.push_data||[]
    push_data.map((item, index)=>{
      let image = image_replace_300x300(item.images)
      elm.push(<div style={Style.goods_item} key={"goods_item"+index}>
        <img src={image} style={Style.goods_item_img} />
        <div style={Style.goods_item_info_box}>
          <div style={Style.goods_item_title}>{item.title}</div>
          <div style={Style.goods_item_time}>铺货时间：{item.date_time}</div>
        </div>
      </div>)
    })
    return elm;
  }
  render_push_goods_log(){
    let elm = new Array()
    if(!this.state.getPushLog.push_info){
      elm.push(<div style={{textAlign:"center",color: "#999999"}}>暂无铺货记录</div>)
      return elm
    }
    elm.push(<div onClick={this.getLogData.bind(this,"")} style={{...Style.push_goods_log_text, fontWeight: this.state.getPushLog.total.push_no_now==""?"bold":"", }}>全部铺货日志</div>)
    let push_info = this.state.getPushLog.push_info||[]
    push_info.map((item, index)=>{
      let push_goods_log_item = {...Style.push_goods_log_item}
      if(item.push_no == this.state.getPushLog.total.push_no_now){
        push_goods_log_item.fontWeight = "bold"
      }
      elm.push(<div onClick={this.getLogData.bind(this,item.date)} key={"push_goods_log_item"+index} style={push_goods_log_item}>
        <div style={Style.push_goods_log_text}>第{item.push_no}次人工铺货日志</div>
        <div style={Style.push_goods_log_text}>{item.date}</div>
      </div>)
    })
    return elm;
  }
  show_log(){
    this.setState({show_log: !this.state.show_log})
  }
  render(){
    return(<>
      {this.state.show_page &&
        <div style={{display:"flex",flexDirection:"column",overflow:this.state.show_log?"hidden":"auto"}}>
          {this.state.has_remote_template != 1 && <div style={{...Style.top_box, position: "sticky", top: 0, zIndex: 2,}}>
            <div style={{display: "flex"}}>
              <img src={OSS+"small_bell_white.png"} style={Style.small_bell_white}/>
              <div style={Style.top_title}>重要提醒</div>
            </div>
            <div style={{marginTop:"1.2vw"}}>
              <div style={Style.top_text}>
                商品已上架至小店售卖，您当前还未配置偏远地区不包邮运费模板，避免亏损，请务必去小店端设置。
                <Text onClick={()=>{window.location.href=Root+"/how_set_template"}} style={{fontSize:"3.45vw",color:"#ffff40",fontWeight:"bold"}}>{"如何设置运费模板 >"}</Text>
              </div>
            </div>
          </div>}
          <div style={Style.small_shop_trusteeship_split_line}> </div>
          <div style={Style.goods_info_title}>
            <div style={Style.info_title}>
              已人工铺货
              {(this.state.getPushLog.total && this.state.getPushLog.total.push_times)||0}
              次｜共 
              {(this.state.getPushLog.total && this.state.getPushLog.total.push_goods_num)||0}
              件
            </div>
            <div onClick={this.show_log.bind(this)} style={Style.see_all_button}>
              {(this.state.getPushLog.total && this.state.getPushLog.total.push_no_now!=="")?
                `第${this.state.getPushLog.total.push_no_now}次铺货`
                :
                "查看全部"
              }
              <img src={OSS+"xiangxia.png"} style={Style.see_all_icon} />
            </div>
          </div>
          <div style={Style.goods_info_list}>
            {this.state.show_log && <div style={Style.goods_info_mask_box}>
              <div style={Style.push_goods_log_line}></div>
              <div style={Style.push_goods_log}>
                <div style={Style.push_goods_log_list}>
                  {this.render_push_goods_log()}
                </div>
              </div>
              <div onClick={()=>{this.setState({show_log:false})}} style={Style.goods_info_mask}></div>
            </div>}
            {this.render_goods_item()}
            {(this.state.getPushLog.push_data && this.state.getPushLog.push_data.length) ?
              <InfiniteScroll loadMore={this.loadMore.bind(this)} hasMore={this.state.hasMore} />
              :
              <div style={Style.no_data_block}>暂无数据</div>
            }
          </div>
        </div>
      }
    </>)
  }
}