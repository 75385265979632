import service from "@/utils/Axios";

export default function Query(type,params){
  let url = ""
  
  switch (type) {
    // 获取公共参数
    case "userInfo":
      url = "/h5/hosting/userInfo"
      break;
    // 保存托管设置选项
    case "saveHostingSetting":
      url = "/h5/hosting/saveHostingSetting"
      break;
    // 获取拼多多订购+授权 二合一码
    case "getQrcode":
      url = "/h5/hosting/getQrcode"
      break;
    // 查询店铺绑定状态
    case "checkBindShop":
      url = "/h5/hosting/checkBindShop"
      break;
    // 获取jssdk信息接口
    case "GetJsSignPack":
      url = "/h5/hosting/getJsSignPack"
      break;
    // 记录自动铺货埋点
    case "visitRecord":
      url = "/h5/hosting/visitRecord"
      params.aliid = sessionStorage.getItem("aliid")
      params.shop_id = sessionStorage.getItem("shop_id")
      params.NoShowLoading = true
      break;
    default:
      break;
  }

  return service({
    url,
    method: 'post',
    params
  })
}