import { configureStore } from '@reduxjs/toolkit'
import Index from './Reducers/Index'
import Public from './Reducers/Public'

const store = configureStore(
    {
        reducer:{
            Index,
            Public,
        },
    }
)
export default store;
