import React from "react";
import { Outlet } from "react-router-dom";
import Dialog from "./Component/dialog";
import PublicQuery from "./API/public";
import ClosePage from "./utils/ClosePage";
import GetURLParams from "./utils/GetURLParams";

class App extends React.Component{
  constructor(props){
    super(props);

    let aliid = GetURLParams("aliid")||""
    aliid && sessionStorage.setItem("aliid", aliid)
    let shop_id = GetURLParams("shop_id")||""
    shop_id && sessionStorage.setItem("shop_id", shop_id)
    
    this.state={
      show: false,
      undifined_user: false,
    }
  }
  componentDidMount(){
    Promise.all([
      PublicQuery("userInfo"),
      PublicQuery("getQrcode"),
    ]).then(res=>{
      // userInfo 返回值
      sessionStorage.setItem("host_setting", JSON.stringify(res[0].data.host_setting||{}));
      sessionStorage.setItem("status", JSON.stringify(res[0].data.status||{}));
      sessionStorage.setItem("token_overdue", res[0].data.token_overdue||"");
      sessionStorage.setItem("has_order", res[0].data.has_order||"");
      sessionStorage.setItem("has_replace", res[0].data.has_replace||"");
      sessionStorage.setItem("zdfx_token_overdue", res[0].data.zdfx_token_overdue||"");
      sessionStorage.setItem("has_remote_template", res[0].data.has_remote_template||"");
      // getQrcode 返回值
      sessionStorage.setItem("getQrcode", JSON.stringify(res[1].data||{}));
      // 展示页面组件
      if(res[0].code == "34002"){
        this.setState({undifined_user: true})        
      }else{
        this.setState({show: true});
      }
    });
  }
  undifined_user(){
    ClosePage()
  }
  render(){
    return (
      <>
        {this.state.show && <Outlet />}
        {this.state.undifined_user && 
          <Dialog
            onConfirmation={this.undifined_user.bind(this)}
            Confirmation="我知道了"
            title="提示"
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"16vw", fontSize:"3.73vw" }}>
              <div>该功能仅限自动分销用户使用，如您已是</div>
              <div>自动分销用户，请进入自动分销端联系客服</div>
            </div>
        </Dialog>}
      </>
    );
  }
}

export default App;