import React from "react";
import Style from "./style";
import { Root } from "@/utils/Public";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    let text_list = {
      has_order: "已产生订单，请及时处理",
      token_overdue: "您接口授权已过期，已影响自动铺货",
      zdfx_token_overdue: "您接口授权已过期，已影响自动铺货",
      has_replace: "有滞销商品需要替换",
    }
    let state_list = {
      has_order: sessionStorage.getItem("has_order"), //是否有回流订单 0:无回流订单 1:有回流订单
      token_overdue: sessionStorage.getItem("token_overdue"), //授权是否过期 0:未过期 1:已过期
      zdfx_token_overdue: sessionStorage.getItem("zdfx_token_overdue"), //自动分销授权是否过期 0:未过期 1:已过期
      // has_replace: sessionStorage.getItem("has_replace"), //是否有待替换商品 0:无 1:有
    }
    let state_index = false
    for (let i in state_list) {
      if (state_list[i] == "1") {
        state_index = i
        break;
      }
    }
    this.state = {
      state_index,
      state_list,
      text_list,
    }
  }
  button(){
    switch (this.state.state_index) {
      case "has_order":
        window.location.href = Root+"/order_proce"
        break;
      case "token_overdue":
        window.location.href = Root+"/authorize_shop"
        break;
      case "zdfx_token_overdue":
        window.location.href = "https://air.1688.com/apps/alim/open/authorize.html?wh_weex=true&appKey=8884967"
        break;
      case "has_replace":
        window.location.href = Root+"/replace_unsalable_goods"
        break;
      default:
        break;
    }
    this.setState({state_index:false})
  }
  render() {
    return (<>
      {this.state.state_index !== false && <>
        <div onClick={this.button.bind(this)} style={Style.box}>
          <span>{this.state.text_list[this.state.state_index]}</span>
          <div style={Style.button}>立即处理</div>
        </div>
      </>}
    </>)
  }
}