import React from "react";
import Style from "./style";
import { OSS } from "@/utils/Public";
import { InfiniteScroll } from "@/UI";
import PushGoodsLog from "@/API/push_goods_log";
import image_replace_300x300 from "@/utils/image_replace_300x300";

export default class Index extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hasMore: false,
      show_page: false,
      getReplaceLog:{},
    }
  }
  componentDidMount(){
    let host_setting = sessionStorage.getItem("host_setting")
    host_setting = host_setting? JSON.parse(host_setting):{}
    this.getDataParams = {
      page:1,
      outside_id : host_setting.outside_id,
    }
    this.getDataFN("").then(res=>{
      this.setState({ 
        getReplaceLog:res.data||{},
        show_page: true,
      })
    })
  }
  getDataFN(){
    return new Promise(async (resolve, reject)=>{
      let res = {}
      res = await PushGoodsLog("getReplaceLog",this.getDataParams)
      if(!res.data.data || res.data.data.length==0){
        this.setState({ hasMore: false })
      }else{
        this.setState({ hasMore: true })
      }
      resolve(res)
    })
  }
  loadMore() {
    return new Promise(async (resolve)=>{
      this.getDataParams.page += 1
      let res = await this.getDataFN()
      let getReplaceLog = this.state.getReplaceLog
      getReplaceLog.data = getReplaceLog.data.concat(res.data.data)
      this.setState({ getReplaceLog })
      resolve("---懒加载触发")
    })
  }
  render_unsalable_goods_list (){
    let elm = new Array()
    let getReplaceLog = this.state.getReplaceLog.data||[]
    getReplaceLog.map((item,index)=>{
      let push_image = image_replace_300x300(item.push_images)
      let replace_image = image_replace_300x300(item.replace_images)
      elm.push(<div style={Style.unsalable_goods_list} key={"render_unsalable_goods_list"+index}>
        {index!=0 && <div style={{...Style.small_shop_trusteeship_split_line,height:"1.33vw",minHeight:"1.33vw"}}></div>}
        <div style={Style.unsalable_goods_item}>
          <img src={push_image} style={Style.unsalable_goods_img} />
          <div>
            <div style={{marginTop:"1.6vw",color:"#FC3928",fontSize:"3.46vw"}}>滞销品</div>
            <div style={Style.unsalable_goods_item_text}>{item.push_title}</div>
          </div>
        </div>
        <div style={Style.unsalable_goods_list_line}>
          <div style={Style.unsalable_goods_list_time}>替换时间：{item.date_time}</div>
        </div>
        <div style={Style.unsalable_goods_item}>
          <img src={replace_image} style={Style.unsalable_goods_img} />
          <div>
            <div style={{marginTop: "1.6vw",color:"#0EAC50",fontSize:"3.46vw"}}>替换品</div>
            <div style={Style.unsalable_goods_item_text}>{item.replace_title}</div>
          </div>
        </div>
      </div>)
    })
    return elm
  }
  render(){
    return(<>
    {this.state.show_page&&
      <div style={{width: "100vw",display:"flex",flexDirection: "column",}}>
        <div style={Style.small_shop_trusteeship_split_line}></div>
        <div style={Style.unsalable_goods_title} >
          <img style={Style.unsalable_goods_title_tips_icon} src={OSS+"tips_icon.png"} />
          替换掉的滞销品，可在小店下架列表中查看
        </div>
        {this.state.getReplaceLog.data && this.state.getReplaceLog.data.length!=0 &&
          <div style={{...Style.getReplaceLog_split_line}}></div>
        }
        <div style={{flex:"1"}}>
          {this.state.getReplaceLog.data && this.state.getReplaceLog.data.length ?
            <>
              {this.render_unsalable_goods_list()}
              <InfiniteScroll loadMore={this.loadMore.bind(this)} hasMore={this.state.hasMore} />
            </>
            :
            <div style={Style.no_data_block}>暂无数据</div>
          }
        </div>
      </div>
    }
    </>)
  }
}