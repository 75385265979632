import React from "react";
import Style from "./style";
import { Tabheader, Swiper } from "@/UI";
import PushGoodsLog from "./push_goods_log";
import UnsalableGoodsLog from "./unsalable_goods_log";
import { OSS } from "@/utils/Public";
import SetPushGoods from "@/Component/set_push_goods";
import SaveQrcodePopup from "@/Component/save_qrcode_popup";
import BottomTipsBox from "@/Component/bottom_tips_box";
import GetURLParams from "@/utils/GetURLParams";
import PublicQuery from "@/API/public";


export default class Index extends React.Component{
  constructor(props){
    super(props);
    document.title = '人工代运营小店'
    let activeIndex = sessionStorage.getItem("small_shop_trusteeship_activeIndex")==1?"1":"0"
    let URL_activeIndex = GetURLParams("small_shop_trusteeship_activeIndex") // 获取url参数
    if( URL_activeIndex == 1 ){
      activeIndex = URL_activeIndex
    }
    let host_setting = sessionStorage.getItem("host_setting")
    host_setting = host_setting? JSON.parse(host_setting):{}

    this.state={
      host_setting,
      activeIndex,
    }
    sessionStorage.removeItem("small_shop_trusteeship_activeIndex")
  }
  componentDidMount(){
    PublicQuery("visitRecord",{
      page_name: "autoPush_adminPage",
      control: "autoPush_adminPage_in"
    })
  }
  save_push_goods(host_setting){
    this.setState({host_setting})
    this.close_set_push_goods()
  }
  close_set_push_goods(){
    this.setState({show_set_push_goods:false})
  }
  tab_change(activeIndex){
    this.setState({activeIndex})
    this.swiper.swipeTo(activeIndex)
  }
  swiper_change(activeIndex){
    this.setState({activeIndex})
  }
  show_set_push_goods_FN(){
    if(this.state.host_setting.host_switch=="1"){
      this.setState({show_set_push_goods:true})
    }else{
      window.Toast.show("人工代运营已关闭，无法再次设置")
    }
  }
  goto_zdfx_FN(){
    PublicQuery("visitRecord",{
      page_name: "autoPush_adminPage",
      control: "autoPush_goZdfx_click"
    })
    this.setState({SaveQrcodePopup:true})
  }
  render(){
    return(<>
      <div style={{ position: "sticky", top: 0, zIndex: 2, background: "#fff" }}>
        <Tabheader style={{
          "--title-font-size": "4vw",
          "--active-title-color":"#FC3928",
          "--active-line-color":"#FC3928",
          "--content-padding":"0",
        }}
        activeKey={this.state.activeIndex+""}
        onChange={this.tab_change.bind(this)}
        >
          <Tabheader.tab title="人工铺货日志" key="0"></Tabheader.tab>
          <Tabheader.tab title="滞销品替换日志" key="1"></Tabheader.tab>
        </Tabheader>
      </div>
      <Swiper
        direction="horizontal"
        defaultIndex={this.state.activeIndex}
        onIndexChange={this.swiper_change.bind(this)}
        ref={(swiper) => { this.swiper = swiper; }}
      >
        <Swiper.Item>
          <div style={Style.swiper_ontent}>
            <PushGoodsLog itemKey="0"></PushGoodsLog>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div style={Style.swiper_ontent}>
            <UnsalableGoodsLog itemKey="1"></UnsalableGoodsLog>
          </div>
        </Swiper.Item>
      </Swiper>
      {/* 设置弹窗 */}
      {this.state.show_set_push_goods &&
        <SetPushGoods onSave={this.save_push_goods.bind(this)} onClose={this.close_set_push_goods.bind(this)}></SetPushGoods>
      }
      {this.state.SaveQrcodePopup &&
        <SaveQrcodePopup close={()=>{this.setState({SaveQrcodePopup:false})}} ></SaveQrcodePopup>
      }
      {/* 底部提示框 */}
      {/* 分销弹窗 */}
      <div style={Style.footer_box}>
        <div style={Style.bottom_tips_box}>
          <BottomTipsBox></BottomTipsBox>
        </div>
        <div onClick={this.show_set_push_goods_FN.bind(this)} style={{...Style.footer_button,...Style.footer_button_1}}>
          <img src={OSS+"gear.png"} style={Style.footer_button_icon} />
          代运营设置
        </div>
        <div onClick={this.goto_zdfx_FN.bind(this)} style={{...Style.footer_button,...Style.footer_button_2}}>去自动分销</div>
      </div>
    </>)
  }
}