import { OSS } from "@/utils/Public"
class GlobalLoading{
  GlobalLoadingBoxStyle = ' display: flex; justify-content: center; align-items: center; background-color: rgba(255,255,255,0); position: fixed; width: 100%; height: 100%; left: 0px; top: 0px; z-index: 999999;'
  GlobalLoadingContentStyle = ' padding: 10px 16px; font-size: 14px; color: #FFFFFF; background-color: rgba(0,0,0,1); border-radius: 5px; user-select: none; opacity: 0; transition: all 1s;display: flex;flex-direction: column;justify-content: center;align-items: center;'
  GlobalLoadingImage = 'margin-bottom:2vw;width:14.66vw; height:15.72vw;'
  show( text = "Loading..." , style){
    let GlobalLoadingBox = document.createElement('div')
    GlobalLoadingBox.setAttribute('id','GlobalLoadingBox')
    GlobalLoadingBox.setAttribute('style', this.GlobalLoadingBoxStyle)
    let GlobalLoadingContent = document.createElement('div')
    GlobalLoadingContent.setAttribute('style', this.GlobalLoadingContentStyle)
    let image = new Image()
    image.src = OSS+"loading_white.png"
    image.className = "RotateAnimation"
    image.setAttribute('style', this.GlobalLoadingImage)
    if(style)GlobalLoadingContent.setAttribute('style', style);
    GlobalLoadingContent.appendChild(image)
    let GlobalLoadingText = document.createElement('div')
    GlobalLoadingText.innerText = text
    GlobalLoadingContent.appendChild(GlobalLoadingText)
    GlobalLoadingBox.appendChild(GlobalLoadingContent)
    document.body.appendChild(GlobalLoadingBox)
    setTimeout(()=>{GlobalLoadingContent.style.opacity = 1},0)
    return GlobalLoadingBox
  }
  close(elm){
    let GlobalLoadingBox = elm ? elm : document.getElementById('GlobalLoadingBox')
    GlobalLoadingBox && GlobalLoadingBox.remove();
  }
}
window.GlobalLoading = new GlobalLoading()
export default GlobalLoading;