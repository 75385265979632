import service from "@/utils/Axios";

async function get_token(){
        // 微信端授权
    let code = new URLSearchParams(window.location.search).get('code');

    // 获取授权
    let res = await service({
        url: "/h5/hosting/getLoginToken",
        method: "post",
        params: {code:code},
    })
    let token = res.data.token
    
    // 判断请求状态
    if( res.code == 0 ){
        // 成功则存储token并返回
        sessionStorage.setItem('x-token', token)
    }else{
        // 输出错误信息
        alert(res.message)
    }
    return token;
}
export default get_token;