import wx from 'weixin-js-sdk';
import { WebOS, OSS, TestBaseURL, BaseURL } from './Public';
import PublicAPI from "../API/public";
function updateAppMessageShareData(){
    if(WebOS == 'wx'){
        // 微信环境时加载jsskd
        PublicAPI('GetJsSignPack',{}).then((res)=>{
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.data.appId, // 必填，公众号的唯一标识
                timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                signature: res.data.signature,// 必填，签名
                jsApiList: ['updateAppMessageShareData'], // 必填，需要使用的JS接口列表
            })
            wx.ready(function(){
                let baseURL = BaseURL
                if( process.env.REACT_APP_ENV_IS_TEST=="prod" ){ // 线上环境
                    baseURL = BaseURL
                }else if( process.env.REACT_APP_ENV_IS_TEST=="test" ){ // 测试环境
                    baseURL = TestBaseURL
                }
                wx.updateAppMessageShareData({ 
                  title: document.title, // 分享标题
                  desc: document.title, // 分享描述
                  // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                  link: `${baseURL}/h5/hosting/getCode?state=&shop_id=${sessionStorage.getItem("shop_id")}&aliid=${sessionStorage.getItem("aliid")}&address=${window.location.pathname.substring(1)}`, 
                  imgUrl: OSS+'true_icon.png', // 分享图标
                  success: function () {
                    console.log("分享链接设置成功")
                  }
                });
            });
        })
    }
}
export default updateAppMessageShareData;