import { OSS } from "@/utils/Public";

export default {
  index_top_background:{
    width: '100vw',
  },
  index_split_line:{
    background: "#EFF0F7",
    height: "2.6vw",
  },
  steps_box:{
    padding: '9vw 3.7vw 20vw 3.7vw'
  },
  steps_title:{
    display: 'flex'
  },
  steps_icon:{
    width: '5.3vw',
    height: '5.3vw',
    background: '#477AFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '4vw',
    marginRight:'2.25vw'
  },
  title_style_box:{
    fontSize: '4vw',
    height: '5.3vw',
    lineHeight: '5.3vw',
    fontWeight: 'bold',
    maxWidth: "80vw",
  },
  steps_text:{
    fontSize: '4vw',
    maxHeight: '12vw',
    lineHeight: '6vw',
    fontWeight: 'bold',
    WebkitLineClamp: "2",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  steps_item:{
    position: "relative",
    borderLeft: "1px solid #477AFF",
    marginLeft: "2.5vw",
    padding: "3vw 0",
  },
  steps_item_done:{
    position: "relative",
    borderLeft: "1px solid #27AE60",
    marginLeft: "2.5vw",
    padding: "4vw 0",
  },
  steps_build_button:{
    width: '82.5vw',
    height: '11.5vw',
    background: '#477AFF',
    borderRadius: '11.5vw',
    textAlign: 'center',
    lineHeight: '11.5vw',
    color: "#ffffff",
    fontSize: '4.3vw',
    marginLeft: "5vw",
  },
  steps_manage_box:{
    marginLeft: "5vw",
  },
  steps_manage_title:{
    display:"flex",
    alignItems: "center",
  },
  steps_loading:{
    width:"12.8vw",
    height:"12.8vw",
  },
  steps_loading_number:{
    color: "#000000",
    fontSize: "4.3vw",
    position: "absolute",
    top: "4vw",
    width: '100%',
    textAlign: 'center',
  },
  steps_loading_text:{
    marginLeft: "3.5vw",
    color: "#515151",
    fontSize: "3.7vw",
  },
  steps_manage_list:{
    marginTop: "4vw",
    marginLeft: "-2vw",
    marginRight: "-2vw"
  },
  steps_manage_item:{
    position: "relative",
    width: '36vw',
    height: '10.5vw',
    background: "#F0F1F5",
    borderRadius: '1vw',
    padding: "3.5vw 3vw",
    display: "inline-block",
    boxSizing: "content-box",
    verticalAlign: "top",
    margin: "1vw",
  },
  steps_manage_item_true:{
    position: "absolute",
    top: "4.5vw",
    right: "3vw",
    width: "3.2vw",
    height: "2.3vw",
  },
  steps_manage_item_title:{
    fontSize: '3.4vw',
    lineHeight: '4vw',
    color: '#656667',
    fontSize: '3.45vw',
    marginBottom: "2.3vw",
  },
  steps_manage_item_text:{
    fontSize: '2.9vw',
    color: "#477AFF"
  },
  Steps_manage_item_loading:{
    width: "3.85vw",
    height: "3.85vw",
  },
  steps_manage_button:{
    marginTop: "5.33vw",
    marginLeft: "-1vw",
    width: '86vw',
    height: '11.5vw',
    background: '#BDBDBD',
    borderRadius: '44px',
    color: "#ffffff",
    fontSize: '4.3vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  steps_manage_button_icon:{
    width: "4vw",
    height: "3.73vw",
    marginRight: "1.6vw",
  },
  goods_list:{
    marginTop: "2.2vw"
  },
  goods_list_item:{
    padding: "4vw 0vw",
    borderBottom: "1px solid #E1E1E1"
  },
  goods_list_item_box:{
    display: "flex",
  },
  goods_list_item_img:{
    width: "34.5vw",
    height: "34.5vw",
    marginRight: "3.2vw",
    border: "1px solid #eeeeee",
    boxSizing: "border-box",
    borderRadius: "2vw",
  },
  goods_list_item_name:{
    fontSize: '4vw',
    color: "#121212",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "54vw",
  },
  goods_list_item_leaderboard_box:{
    height: "5.7vw",
    fontSize: "2.9vw",
    marginTop: "1.5vw"
  },
  goods_list_item_leaderboard:{
    height: "5.7vw",
    display: "inline-block",
    borderRadius: "5.7vw",
    background: "#FEEFDF",
  },
  goods_list_leaderboard_logo:{
    height: "5.7vw",
    position: 'relative',
  },
  goods_list_number_logo:{
    width: "1.5vw",
    height: "3.1vw",
    position: 'relative',
    top: '0.3vw',
    margin: "0vw 1vw"
  },
  goods_list_item_price:{
    fontSize: '3.5vw',
    lineHeight: '3.2vw',
    color: '#6A6A6A',
    marginTop: '2.5vw',
  },
  goods_list_item_info_box:{
    width: '54vw',
    height: '15.5vw',
    marginTop: "1.3vw",
    borderRadius: '1.6vw',
    background: `url(${OSS}goods_list_item_info_box_background.png) right center / 20.5vw 15.5vw no-repeat #F8F8F8`,
    display: "flex",
    textAlign: "center",
    position: "relative",
  },
  goods_list_item_info_profit:{
    width: "16.4vw",
  },
  goods_list_item_info_split_line:{
    width: '6vw',
    height: '1px',
    background: 'rgb(218,218,218)',
    transform: 'rotate(90deg)',
    position: 'absolute',
    top: '8vw',
    left: '13.5vw',
    lineHeight: "4vw",
  },
  goods_list_item_info_sales_volume:{
    width: "16.4vw",
    lineHeight: "4vw",
  },
  goods_list_item_info_cycle:{
    width: "21vw",
    lineHeight: "4vw",
  },
  goods_list_item_info_top:{
    marginTop: "3vw",
    lineHeight: "4vw",
    fontSize: "2.9vw",
  },
  goods_list_item_info_bottom:{
    marginTop: "2.5vw",
    lineHeight: "4vw",
    fontSize: "3.4VW",
    positions: ""
  },
  goods_list_item_info_date:{
    fontSize: '5.85vw',
    position: 'relative',
    top: '0.5vw',
  },
  footer_box:{
    width: '100%',
    height: "18vw",
    boxShadow: "0px -4px 10px -4px rgba(179, 179, 179, 0.25)",
    position: 'fixed',
    bottom: '0',
    background: '#ffffff',
    padding: "0vw 3.3vw",
    boxSizing: 'border-box',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer_button:{
    width: "93vw",
    height: "12vw",
    borderRadius: "12vw",
    background: "#BDBDBD",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4.8vw',
    color: "#ffffff",
  },
  footer_selected_num:{
    fontSize: '3.2vw',
    lineHeight: '4vw',
    color: '#727272',
  },
  footer_selected_clear:{
    marginTop: "2.4vw",
    fontSize: '3.2vw',
    lineHeight: '4vw',
    color: '#477AFF',
  }
}
