export default {
  box:{
    position: 'absolute',
    width: '78.6vw',
    left: 'calc(50% - 39.3vw)',
    top: 'calc(50% - 29.7vw)',
    background: '#FFFFFF',
    borderRadius: '3.2vw',
    padding: '0vw 0vw 18vw',
  },
  title:{
    color: '#333333',
    fontSize: '4.8vw',
    marginTop: "5.6vw",
    marginBottom: "4.8vw",
    textAlign: 'center',
    fontWeight: 'bold',
  },
  button_box:{
    display: "flex",
    justifyContent: 'center',
    position: "absolute",
    bottom: "5.6vw",
    width: "67.4vw",
    margin: "0vw 5.6vw",
  },
  button_cancel:{
    flex: '1',
    margin: '1.7vw',
    height:"10.7vw",
    color: '#477AFF',
    border: '1px solid #477AFF',
    boxSizing: 'border-box',
    borderRadius: '10.7vw',
    fontSize: '4.25vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button_confirmation:{
    flex: '1',
    margin: '1.7vw',
    height:"10.7vw",
    color: "#ffffff",
    background: '#477AFF',
    border: '1px solid #477AFF',
    boxSizing: 'border-box',
    borderRadius: '10.7vw',
    fontSize: '4.25vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}