import React from "react";

export default class Index extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <span 
        style={this.props.style} 
        onClick={()=>{ typeof this.props.onClick=="function" && this.props.onClick()}}
      >
        {this.props.children}
      </span>
    )
  }

}
