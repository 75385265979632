import React from "react";
import Style from "./style";
import { OSS, Root } from "@/utils/Public";
import { Text } from "@/UI";
import Dialog from "@/Component/dialog";
import PublicQuery from "@/API/public";
import DrawImage from "@/utils/DrawImage";

export default class Index extends React.Component {
  constructor(props) {
    document.title = '授权'
    super(props);
    let getQrcode = sessionStorage.getItem("getQrcode")
    getQrcode = getQrcode?JSON.parse(getQrcode):{};

    this.state = {
      getQrcode,
      qrcode_oss:"",
      show_error_tips_dialog: false,
      show_success_tips_dialog: false,
    }
  }
  componentDidMount() {
    PublicQuery("visitRecord",{
      page_name: "autoPush_authorizeShopPage",
      control: "autoPush_authorizeShopPage_in"
    })
    DrawImage( this.state.getQrcode.qrcode_oss, OSS + "qrcode_scan_twice_icon.png").then(res=>{
      if(res){
        this.setState({qrcode_oss: res})
      }
    })
  }
  build_shop_done(){
    PublicQuery("visitRecord",{
      page_name: "autoPush_authorizeShopPage",
      control: "autoPush_hasAuthorizeShop_click"
    })
    // 查询授权状态
    PublicQuery("userInfo").then(res=>{
      sessionStorage.setItem("host_setting", JSON.stringify(res.data.host_setting||{}));
      sessionStorage.setItem("status", JSON.stringify(res.data.status||{}));
      sessionStorage.setItem("token_overdue", res.data.token_overdue||"");
      sessionStorage.setItem("has_order", res.data.has_order||"");
      sessionStorage.setItem("has_replace", res.data.has_replace||"");
      sessionStorage.setItem("zdfx_token_overdue", res.data.zdfx_token_overdue||"");
      sessionStorage.setItem("has_remote_template", res.data.has_remote_template||"");

      if(res.data.token_overdue == "0"){
        this.setState({show_success_tips_dialog:true})
      }else{
        this.setState({show_error_tips_dialog:true})
      }
      
    });
  }
  lang_touch_down_FN(){
    this.lang_touch_timer = true
    setTimeout(()=>{
      if(this.lang_touch_timer){
        PublicQuery("visitRecord",{
          page_name: "autoPush_authorizeShopPage",
          control: "autoPush_saveQrCode_hold"
        })
      }
    },500)
  }
  lang_touch_up_FN(){
    this.lang_touch_timer = false
  }
  build_shop_done_dialog_confirm(){
    this.setState({show_success_tips_dialog:false});
    // 未铺货则跳转首页,否则跳转铺货日志页
    let host_setting = sessionStorage.getItem("status")
    host_setting = host_setting?JSON.parse(host_setting):{}
    if( host_setting.step!="3" ){
      window.location.href = Root+"/first_entry"
    }else{
      window.location.href = Root+"/small_shop_trusteeship"
    }
  }
  render() {
    return (
      <>
        {this.state.show_error_tips_dialog &&
          <Dialog
            onConfirmation={()=>{this.setState({show_error_tips_dialog:false})}}
            Confirmation="我知道了"
            title="授权失败"
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"26vw",fontSize:"3.73vw" }}>
              <div>系统检查您未完成授权</div>
              <div>请用拼多多商家版扫码2次完成授权</div>
            </div>
        </Dialog>}
        {this.state.show_success_tips_dialog &&
          <Dialog
            onConfirmation={this.build_shop_done_dialog_confirm.bind(this)}
            Confirmation="我知道了"
          >
            <div style={{ textAlign: 'center', lineHeight: '5.6vw', height:"20vw",fontSize:"4vw" }}>
              <div>待系统检测授权成功后</div>
              <div>将会自动开启人工代运营服务</div>
            </div>
        </Dialog>}
        
        <div style={Style.build_shop_top_box}>
          <img style={Style.build_shop_top_background} src={OSS + "build_shop_top_background.png"} />
          <div style={Style.build_shop_top_text}>您的拼多多小店授权已过期，请按以下方式完成授权</div>
        </div>
        <div style={Style.build_shop_split_line}></div>
        <div style={Style.build_shop_body}>
          <div style={Style.build_shop_body_title}>
            <div style={Style.build_shop_body_title_icon}></div>
            用<Text style={Style.build_shop_body_title_name}>拼多多商家版</Text>扫下方二维码2次
          </div>
          <div style={Style.build_shop_Tips}>
            <div style={Style.build_shop_Tips_item}>
              <div style={Style.build_shop_Tips_icon}></div>
              第一次扫码：点击“去续费”订购分销宝
            </div>
            <div style={Style.build_shop_Tips_item}>
              <div style={Style.build_shop_Tips_icon}></div>
              第二次扫码：订购成功后完成授权确认
            </div>
          </div>
          <div style={Style.build_shop_qrcode_box}>
            {this.state.qrcode_oss && <>
              <img onTouchStart={this.lang_touch_down_FN.bind(this)} onTouchEnd={this.lang_touch_up_FN.bind(this)} style={Style.save_qrcode_img} src={ this.state.qrcode_oss } />
              <div style={Style.build_shop_qrcode_image_box}>
                <img style={Style.build_shop_qrcode_image} src={ this.state.qrcode_oss } />
              </div>
              <div style={Style.build_shop_qrcode_text}>长按选择保存到手机</div>
            </>}
          </div>
          <div onClick={this.build_shop_done.bind(this)} style={Style.build_shop_button}>已授权开始铺货</div>
        </div>
      </>
    )
  }
}