import { createSlice } from '@reduxjs/toolkit'

export const Index = createSlice({
  name: 'Index',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.params
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = Index.actions

export default Index.reducer